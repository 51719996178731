import { CONST } from "../../Constants";

export class User {
  constructor() {
    this.uuid = null;
    this.calendar_id = null;
    this.email = null;
    this.login_erros = null;
    this.firstname = null;
    this.lastname = null;
    this.company = null;
    this.position = null;
    this.mobile = null;
    this.phone = null;
    this.street = null;
    this.zip = null;

    this.email_confirmed = false;
    this.dataprivacy = null;
    this.city = null;
    this.state = null;
    this.country = null;
    this.lang = null;
    this.name = null;
    this.connectionType = null;
    this.systemRoleValue = 1000;
    this.notifications = null;
    this.ws_token = null;
    this.instructions = null;

    this.__type = "user";
  }

  Merge(user) {
    this.mergeAttribute("uuid", user);
    this.mergeAttribute("firstname", user);
    this.mergeAttribute("lastname", user);
    this.mergeAttribute("company", user);
    this.mergeAttribute("position", user);
    this.mergeAttribute("mobile", user);
    this.mergeAttribute("phone", user);
    this.mergeAttribute("street", user);
    this.mergeAttribute("zip", user);
    this.mergeAttribute("city", user);
    this.mergeAttribute("state", user);
    this.mergeAttribute("country", user);
    this.mergeAttribute("lang", user);
    this.mergeAttribute("name", user);
    this.mergeAttribute("connectionType", user);
    this.mergeAttribute("email_confirmed", user);
    this.mergeAttribute("dataprivacy", user);
    this.mergeAttribute("notifications", user);
    this.mergeAttribute("systemRoleValue", user);
    this.mergeAttribute("ws_token", user);
    this.mergeAttribute("instructions", user);
  }

  mergeAttribute(attribute, user) {
    if (user[attribute] != null && user[attribute] != this[attribute])
      this[attribute] = user[attribute];
  }

  GetName() {
    return _GetName(this);
    /*if(this.firstname && this.firstname.length>1 || this.lastname && this.lastname.length>1)
			return this.firstname+CONST._space+this.lastname;
    if(this.name && this.name.length>1)
      return this.name;
    return this.email;
    */
  }

  static Parse(json) {
    let user = new User();
    user.uuid = json.uuid;
    user.calendar_id = json.calendar_id;
    user.email = json.email;
    user.name = json.name ? json.name : null;
    user.login_erros = json.login_erros;
    user.firstname = json.firstname;
    user.lastname = json.lastname;
    user.company = json.company;
    user.position = json.position;
    user.mobile = json.mobile;
    user.phone = json.phone;
    user.street = json.street;
    user.zip = json.zip;
    user.email_confirmed = json.email_confirmed ? true : false;
    user.dataprivacy = json.dataprivacy;
    user.city = json.city;
    user.notifications = json.notifications;
    user.state = json.state;
    user.country = json.country;
    user.lang = json.lang;
    user.systemRoleValue = json.systemRoleValue;
    user.status = json.status ? json.status : CONST.userStates.available;
    user.ws_token = json.ws_token;
    user.instructions = json.instructions;

    if (user.name) {
      let parts = user.name.split(" ");
      if (!user.firstname && parts.length > 0) user.firstname = parts[0];
      if (!user.lastname && parts.length > 1)
        user.lastname = parts[parts.length - 1];
    }
    return user;
  }

  static ParseOtherUser(json) {
    let user = User.Parse(json);
    if (json.type === "connected")
      user.connectionType = User.ConnectionTypes.CONNECTED;
    else if (json.type === "request") {
      if (json.direction === ">")
        user.connectionType = User.ConnectionTypes.REQUESTED_I;
      else user.connectionType = User.ConnectionTypes.REQUESTED_ME;
    }

    return user;
  }
}

export function _GetName(user) {
  if (
    (user?.firstname && user?.firstname.length > 1) ||
    (user?.lastname && user?.lastname.length > 1)
  )
    return user?.firstname + CONST._space + user?.lastname;
  if (user?.name && user?.name.length > 1) return user?.name;
  return user?.email;
}

export function _GetInitials(user) {
  let initials = CONST._empty;

  if (user?.firstname && user?.firstname.length > 1)
    initials += user.firstname.substring(0, 1);
  if (user?.lastname && user?.lastname.length > 1)
    initials += user.lastname.substring(0, 1);

  if (initials === CONST._empty) initials = user?.email;

  if (initials == null) initials = "no user";

  return initials;
}

User.ConnectionTypes = {
  CONNECTED: "CONNECTED",
  REQUESTED_ME: "REQUESTED_ME",
  REQUESTED_I: "REQUESTED_I",
};
