import {
	WORKSPACE_GET, 
	WORKSPACE_UPDATE,
	/*WORKSPACE_CREATE_PENDING, WORKSPACE_CREATE_SUCCESS, WORKSPACE_CREATE_ERROR,*/
	WORKSPACE_GET_AVAILABLE,
  /*WORKSPACE_DELETE_PENDING, WORKSPACE_DELETE_SUCCESS, WORKSPACE_DELETE_ERROR,*/
  WORKSPACEUSERS_GET_SUCCESS,WORKSPACEUSERS_UDPATE_SUCCESS, WORKSPACEUSERS_ADD_SUCCESS, WORKSPACEUSERS_DELETE_SUCCESS,
  WORKSPACE_GET_AVAILABLE_IN_COMPANIES
	
} from './../actions/workspace';
import {PAGE_GET, PAGE_SET}  from './../actions/page';
import {APP_LOGOUT_USER}  from './../actions/app';
import {User}  from './../_models/User';
import {initialState}  from 'initialState.js';
import {ParsePage} from '_redux/_models/Page';
import { ParseWorkspace } from '../_models/Workspace';

export function workspaceReducer(state = initialState, action) {	
	switch(action.type) {
		case APP_LOGOUT_USER:
			return{
				...state,
				availableWorkspaces:[],
				actualWorkspace:null
      }
      
     case PAGE_SET.SUCCESS:
    {      
      let page = ParsePage(action.payload.page);
       
      if(state.actualWorkspace && state.actualWorkspace.uuid === action.payload.entity_id)
      {
        let actualWorkspace = {...state.actualWorkspace};
        actualWorkspace.page = page;
       // console.log('found', page);

        //console.log('PAGE_SET.SUCCESS:', actualWorkspace);
        return {
          ...state,
          pending: false,
          actualWorkspace: actualWorkspace
        }
      }
     
      return state;
    }
		/*#region WORKSPACE_GET*/
		case WORKSPACE_GET.PENDING: 
			return {...state,error: null,pending: true}
    case WORKSPACE_GET.SUCCESS:	     
    {
      let actualWorkspace =  ParseWorkspace(action.payload.workspace);
      actualWorkspace.page = ParsePage(action.payload.workspace.page)
      let contacts = [];
      for(let i in action.payload.workspace.contacts)
      {
        let user = User.Parse(action.payload.workspace.contacts[i]);
        if(user)
          contacts.push(user);
      }
     
      
			return {
				...state,				
				pending: false,
				actualWorkspace: actualWorkspace
      }
    }
    case PAGE_GET.SUCCESS: {
      
      //TODO hot fix - to update language changed eidt page views
      let page = ParsePage(action.payload.page);
      if(page.uuid === state.actualWorkspace?.page?.uuid){
        state.actualWorkspace.page = page;        
        return {
          ...state,				
          pending: false,
          actualWorkspace: {...state.actualWorkspace}
        }
      }
     
    /*  
      return {
        ...state,
        pending: false,
        actualPage: ParsePage(action.payload.page)
      };*/
    }

		case WORKSPACE_GET.ERROR:					
			return {...state,pending: false,error:action.info}
		/*#endregion WORKSPACE_GET*/

    case WORKSPACE_UPDATE.SUCCESS:	
		{
      let actualWorkspace =  ParseWorkspace(action.payload.workspace);
      return {
        ...state,				
        pending: false,
        actualWorkspace: actualWorkspace
      }
    }
    case WORKSPACE_UPDATE.PENDING:	
		{
    
      return {
        ...state,				
        pending: true
      }
    }
    case WORKSPACEUSERS_GET_SUCCESS:	
		{            
      return {...state,pending: false,workspaceUsers:action.payload}
    }
    case WORKSPACEUSERS_UDPATE_SUCCESS:	
		{      
      for(let i in state.workspaceUsers.users)
      {
        if(state.workspaceUsers.users[i].uuid === action.payload.uuid)
          state.workspaceUsers.users[i].role_value = action.payload.role_value;
      }
      return {...state,pending: false,workspaceUsers:{...state.workspaceUsers}}
    }
    case WORKSPACEUSERS_DELETE_SUCCESS:	
		{      
      let deleteQueue=[];
      for(let i in state.workspaceUsers.users)
      {
        if(action.payload.list.includes(state.workspaceUsers.users[i].uuid))
          deleteQueue.push(i);          
      }
      while(deleteQueue.length>0)
      {
        state.workspaceUsers.users.splice(deleteQueue[0],1);
        deleteQueue.splice(0,1);
      }
      return {...state,pending: false,workspaceUsers:{...state.workspaceUsers}}
    }
    
    case WORKSPACEUSERS_ADD_SUCCESS:	
		{      
      return {...state,pending: false,workspaceUsers:action.payload}
    }
        
		/*#region WORKSPACE_GET_AVAILABLE*/
		case WORKSPACE_GET_AVAILABLE.PENDING: 
			return {...state,error: null,pending: true}
		case WORKSPACE_GET_AVAILABLE.SUCCESS:		
			return {
				...state,
				pending: false,
				availableWorkspaces: action.payload.workspaces
			}
		case WORKSPACE_GET_AVAILABLE.ERROR:			
			return {...state,pending: false,error: action.info}      
    case WORKSPACE_GET_AVAILABLE_IN_COMPANIES.SUCCESS:      
			return {
				...state,
				pending: false,
				availableInCompanies: action.payload.workspaces
			}      
		default: 
			return state;
	}
}
export const IsError = state => state.workspace.error;
export const IsPending = state => state.workspace.pending;
export const GetAvailable = state => state.workspace.availableWorkspaces;
export const GetActual = state => state.workspace.actualWorkspace;
export const GetWorkspaceUsers = state => state.workspace.workspaceUsers;
export const GetAllInCompanies = state => state.workspace.availableInCompanies;
