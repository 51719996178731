
export class UserDB
{
	constructor() {    
		this.users = new Map();
	}

  AddUser(user)
  {
    
    if(!user.email)
      return;

    //console.log(user, this.users.get(user.email))
    if(this.users.get(user.email))
    {
      this.users.get(user.email).Merge(user);
    }
    else
    {
      this.users.set(user.email, user);
    }

    return this.users.get(user.email);
  }

	SetUsers(users)
	{
		users.map((user)=>this.users.set(user.email, user));

	}
  UpdateStatus(email, status)
  {
    if(this.users.get(email))
      this.users.get(email).status = status;
  }
  

	FindUserByEmail(email)
	{
		return this.users.get(email);
	}
	FindUserByUuid(uuid)
	{
    let foundUser = null;
    this.users.forEach((user)=>{if(user.uuid == uuid)foundUser = user;})
		return foundUser;
	}

  

}