import React from "react";
import { createRoot } from 'react-dom/client';

import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
/*import * as serviceWorker from './serviceWorker';*/
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { apiReducer } from "./_redux/reducers/api";
import { appReducer } from "./_redux/reducers/app";
import { loginReducer } from "./_redux/reducers/login";
import { antReducer } from "./_redux/reducers/ant";
import { companyReducer } from "./_redux/reducers/company";
import { workspaceReducer } from "./_redux/reducers/workspace";
import { languageReducer } from "./_redux/reducers/language";

import { messageReducer } from "./_redux/reducers/message";
import { usersReducer } from "./_redux/reducers/users";
import { documentsReducer } from "./_redux/reducers/document";
import { stageReducer } from "./_redux/reducers/stage";
import { pageReducer } from "./_redux/reducers/page";
import { calendarReducer } from "./_redux/reducers/calendar";
import { socialReducer } from "./_redux/reducers/social";
import { websocketReducer } from "./_redux/reducers/websocket";

import { meetingReducer } from "./_redux/reducers/meeting";
import { initialState } from "./initialState";

import "./index.css";
import "./i18n";
import { loadingActionReducer } from "./_redux/reducers/loader";
import { ModalProvider } from "styled-react-modal";
import { openModalReducer } from "./_redux/reducers/modal";
import { surveyReducer } from "./_redux/reducers/survey";
import { callReducer } from "./_redux/reducers/call";
import { instructionsReducer } from "./_redux/reducers/instructions";

import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";


const App = React.lazy(() => import("./App"));

const middlewares = [thunk];
const rootReducer = combineReducers({
  apiReducer,
  app: appReducer,
  login: loginReducer,
  ant: antReducer,
  company: companyReducer,
  workspace: workspaceReducer,
  messages: messageReducer,
  users: usersReducer,
  meeting: meetingReducer,
  documents: documentsReducer,
  stage: stageReducer,
  page: pageReducer,
  calendar: calendarReducer,
  loader: loadingActionReducer,
  modal: openModalReducer,
  language: languageReducer,
  survey: surveyReducer,
  websocket: websocketReducer,
  call: callReducer,
  instructions: instructionsReducer,
  social:socialReducer
});
// export const store = createStore(
//   rootReducer,
//   initialState,
//   applyMiddleware(...middlewares)
// );

const migrations = {
  0: (state) => {
    return {
      ...state,
      language: undefined,
    };
  },
  1: (state) => {
    return {
      ...state,
      language: state.languages,
    };
  }
};

const persistConfig = {
  key: "languageType",
  version: 1,
  storage: storage,
  whitelist: ["language"], // which reducer want to store
  migrate: createMigrate(migrations, { debug: false }),
};
const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(...middlewares);
export const store = createStore(pReducer, initialState, middleware);
const persistor = persistStore(store);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <React.Suspense fallback={<div>Loading</div>}>
          <ModalProvider>
            <App />
          </ModalProvider>
        </React.Suspense>
      </HashRouter>
    </PersistGate>
  </Provider>
);
//serviceWorker.unregister();


