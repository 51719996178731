import fetch from "./_api_fetchTimeout";
import { apiRequestStart, apiRequestDone } from "./../actions/api";
import { GetLocalItem, SetLocalItem } from "LocalStorage";
import { CONST } from "../../Constants";
import { GetDomain, GetWorkspace } from "KlarHelper";
import { auth } from "./login";
import { store } from "../../index.js";

/* deprecated
const _API_URL_DEV = "https://api.dev.klar.land/";
const _API_URL = "https://api.klar.land/";
// TODO activate again if merged to master
const _API_URL_LOCAL = "http://localhost:8000/";
//const _API_URL_LOCAL = "https://api.klar.land/";
// const _API_URL_LOCAL = "http://localhost:8000/";
// const _API_URL_LOCAL = "https://api.dev.klar.land/";
const _API_URL_LOCAL_STAGE = "http://localhost:8081/api/";
const _API_URL_STAGE = "https://d01.klar.land/api/";
*/
export const _API_KEY = "TfpLuXW%zQLRoxF6%7!rATNHFRWe78fm";
const _API_VERSION = "";
let _API_TOKEN = "";

const METHODS = { post: "POST", get: "GET" };

export const EndpointFunctionMap = new Map();

export const Endpoints = {
  public_data: { id: "99", path: "public_data", method: METHODS.get },
  login: { id: "100", path: "auth/login", method: METHODS.post },
  logout: { id: "101", path: "auth/logout", method: METHODS.post },
  magic_login: {
    id: "551",
    path: "auth/magic_link/login",
    method: METHODS.post,
  },
  register: { id: "102", path: "auth/register", method: METHODS.post },
  requestreset: { id: "103", path: "auth/reset", method: METHODS.post },
  reset: { id: "104", path: "auth/reset", method: METHODS.post },
  auth: { id: "105", path: "auth/refresh", method: METHODS.post },
  me: { id: "106", path: "auth/me", method: METHODS.post },
  user_update: { id: "107", path: "auth/update_user", method: METHODS.post },
  invite: { id: "108", path: "auth/invite", method: METHODS.post },
  update_user_instructions: {
    id: "109",
    path: "auth/user/update_instruction",
    method: METHODS.post,
  },
  invite_workspace: {
    id: "508",
    path: "workspace/users/invite",
    method: METHODS.post,
  },

  confirm_email: {
    id: "110",
    path: "auth/confirm_email",
    method: METHODS.post,
  },
  request_confirm_email: {
    id: "111",
    path: "auth/request_confirm_email",
    method: METHODS.post,
  },

  request_confirm_terms: {
    id: "188",
    path: "/dataprivacy/set",
    method: METHODS.post,
  },

  conference_get_user: {
    id: "221",
    path: "conference/getStreamUser",
    method: METHODS.post,
  },
  conference_set_user: {
    id: "222",
    path: "conference/setStreamUser",
    method: METHODS.post,
  },

  company_getAvailable: {
    id: "220",
    path: "company/get_all",
    method: METHODS.post,
  },
  company_create: { id: "221", path: "company/create", method: METHODS.post },

  company_get: { id: "222", path: "company/get", method: METHODS.post },
  company_udpate: { id: "223", path: "company/update", method: METHODS.post },
  company_delete: { id: "224", path: "company/delete", method: METHODS.post },
  company_set_default: {
    id: "287",
    path: "company/workspace/set_default",
    method: METHODS.post,
  },

  workspace_getAvailable: {
    id: "230",
    path: "workspace",
    method: METHODS.post,
  },
  workspace_getAvailableInCompanies: {
    id: "230a",
    path: "workspace/getAll",
    method: METHODS.post,
  },
  workspace_create: {
    id: "231",
    path: "workspace/create",
    method: METHODS.post,
  },
  workspace_get: { id: "232", path: "workspace/get", method: METHODS.post },
  workspace_udpate: {
    id: "233",
    path: "workspace/update",
    method: METHODS.post,
  },
  workspace_delete: {
    id: "234",
    path: "workspace/delete",
    method: METHODS.post,
  },

  workspace_users_get: {
    id: "235",
    path: "workspace/users_get",
    method: METHODS.post,
  },
  workspace_user_create: {
    id: "236",
    path: "workspace/user_add",
    method: METHODS.post,
  },
  workspace_user_update: {
    id: "237",
    path: "workspace/user_update",
    method: METHODS.post,
  },
  workspace_users_delete: {
    id: "238",
    path: "workspace/users_delete",
    method: METHODS.post,
  },
  workspace_users_add: {
    id: "239",
    path: "workspace/users_add",
    method: METHODS.post,
  },

  workspace_tag_edit: {
    id: "309",
    path: "company/user/tag/edit",
    method: METHODS.post,
  },
  workspace_send_request: {
    id: "284",
    path: "workspace/request_access",
    method: METHODS.post,
  },
  workspace_verify_access: {
    id: "285",
    path: "workspace/verify_access",
    method: METHODS.post,
  },
  workspace_grant_access: {
    id: "392",
    path: "workspace/grant_access",
    method: METHODS.post,
  },
  workspace_add_user_csv: {
    id: "287",
    path: "workspace/add/users/csv",
    method: METHODS.post,
  },

  stage_getAvailable: { id: "240", path: "stage", method: METHODS.post },
  stage_create: { id: "241", path: "stage/create", method: METHODS.post },
  stage_get: { id: "242", path: "stage/get", method: METHODS.post },
  stage_update: { id: "243", path: "stage/update", method: METHODS.post },
  stage_delete: { id: "244", path: "stage/delete", method: METHODS.post },
  stage_get_iframe: {
    id: "444",
    path: "stage/iframe/get",
    method: METHODS.post,
  },
  stage_check_iframe: {
    id: "444",
    path: "stage/iframe/check",
    method: METHODS.post,
  },

  /*stage_upload:{id:"245", path:"stage/upload", method:METHODS.post}, not implemented in api*/

  calendar_get: { id: "281", path: "calendar/get", method: METHODS.post },
  calendar_add: { id: "282", path: "calendar/add", method: METHODS.post },
  calendar_update: { id: "283", path: "calendar/update", method: METHODS.post },
  calendar_delete: { id: "284", path: "calendar/delete", method: METHODS.post },
  calendar_add_meeting: {
    id: "285",
    path: "calendar/add_meeting",
    method: METHODS.post,
  },

  message_getAvailable: { id: "290", path: "msg", method: METHODS.post },
  message_get: { id: "291", path: "msg/get", method: METHODS.post },
  message_create: { id: "292", path: "msg/create", method: METHODS.post },
  message_delete: { id: "293", path: "msg/delete", method: METHODS.post },
  message_getNew: { id: "294", path: "msg/get_new", method: METHODS.post },
  message_setShown: { id: "295", path: "msg/set_shown", method: METHODS.post },
  message_sendFile: {
    id: "296",
    path: "message/send/file",
    method: METHODS.post,
  },
  

  users_getAvailable: { id: "270", path: "users", method: METHODS.post },
  users_get: { id: "271", path: "users/get", method: METHODS.post },
  users_add: { id: "272", path: "users/add", method: METHODS.post },
  users_deny: { id: "273", path: "users/deny", method: METHODS.post },
  users_createInvitation: {
    id: "274",
    path: "users/create_invitation",
    method: METHODS.post,
  },
  users_searchFast: {
    id: "276",
    path: "users/search/fast",
    method: METHODS.post,
  },
  users_searchDetailed: {
    id: "277",
    path: "users/search/detailed",
    method: METHODS.post,
  },
  users_getStatus: { id: "278", path: "users/status", method: METHODS.post },

  call_init: { id: "400", path: "call/init", method: METHODS.post },
  call_hangup: { id: "402", path: "call/hangup", method: METHODS.post },
  call_pickup: { id: "401", path: "call/pickup", method: METHODS.post },
  call_invite: { id: "403", path: "call/invite", method: METHODS.post },
  call_disconnect: { id: "404", path: "call/disconnect", method: METHODS.post },

  meeting_getAll: { id: "300", path: "meeting", method: METHODS.post },
  meeting_get: { id: "301", path: "meeting/get", method: METHODS.post },
  meeting_create: { id: "302", path: "meeting/create", method: METHODS.post },
  meeting_update: { id: "303", path: "meeting/update", method: METHODS.post },
  meeting_delete: { id: "304", path: "meeting/delete", method: METHODS.post },
  meeting_setUser: {
    id: "305",
    path: "meeting/set_user",
    method: METHODS.post,
  },
  meeting_deleteUser: {
    id: "306",
    path: "meeting/delete",
    method: METHODS.post,
  },
  meeting_getUser: { id: "307", path: "meeting/get", method: METHODS.post },
  meeting_getUserByStreamIid: {
    id: "308",
    path: "meeting/get_user_bystreamid",
    method: METHODS.post,
  },
  meeting_getUsers: {
    id: "309",
    path: "meeting/get_users",
    method: METHODS.post,
  },
  meeting_setUsers: {
    id: "310",
    path: "meeting/set_users",
    method: METHODS.post,
  },
  meeting_getUsers1: {
    id: "311",
    path: "meeting/get_users1",
    method: METHODS.post,
  },
  meeting_setResponse: {
    id: "312",
    path: "meeting/set_response",
    method: METHODS.post,
  },

  meeting_setUp: {
    id: "340",
    path: "meeting/signup",
    method: METHODS.post,
  },
  meeting_recording: {
    id: "380",
    path: "meeting/recording/get",
    method: METHODS.get,
  },

  group_create: {
    id: "330",
    path: "message/group/create",
    method: METHODS.post,
  },
  group_update: {
    id: "331",
    path: "message/group/update",
    method: METHODS.post,
  },
  group_delete: {
    id: "332",
    path: "message/group/delete",
    method: METHODS.post,
  },
  group_get: { id: "333", path: "message/group/get", method: METHODS.post },
  group_getUserGroups: {
    id: "334",
    path: "message/group/user/get/groups",
    method: METHODS.post,
  },
  group_addUsers: {
    id: "335",
    path: "message/group/add/users",
    method: METHODS.post,
  },
  group_removeUsers: {
    id: "336",
    path: "message/group/remove/users",
    method: METHODS.post,
  },
  group_removeMyself: {
    id: "337",
    path: "message/group/remove/user/me",
    method: METHODS.post,
  },
  group_sendMessage: {
    id: "338",
    path: "message/group/send",
    method: METHODS.post,
  },
  group_getAll: {
    id: "339",
    path: "message/group/getAll",
    method: METHODS.post,
  },
  group_updateUsers: {
    id: "340",
    path: "message/group/update/users",
    method: METHODS.post,
  },

  document_get: { id: "262", path: "doc/get", method: METHODS.post },
  document_create: { id: "261", path: "doc/create", method: METHODS.post },
  document_update: { id: "263", path: "doc/update", method: METHODS.post },
  document_delete: { id: "264", path: "doc/delete", method: METHODS.post },
  document_getAvailable: { id: "260", path: "doc", method: METHODS.post },
  document_getRecording: {
    id: "260",
    path: "meeting/recording/get/all",
    method: METHODS.post,
  },

  page_get: { id: "321", path: "page/get", method: METHODS.post },
  page_update: { id: "323", path: "page/update", method: METHODS.post },
  page_create: { id: "326", path: "page/create", method: METHODS.post },
  file_upload: { id: "324", path: "file/upload", method: METHODS.post },
  download_ics: {
    id: "325",
    path: "calendar/ics/download",
    method: METHODS.get,
  },
  calendar_edite: {
    id: "283",
    path: "calendar/meeting/setEditable",
    method: METHODS.post,
  },
  analytics_load: {
    id: "501",
    path: "stats/load",
    method: METHODS.post,
  },
  analytics_push: {
    id: "502",
    path: "stats/push",
    method: METHODS.post,
  },
  survey_get: {
    id: "360",
    path: "survey/get",
    method: METHODS.post,
  },
  survey_result_get: {
    id: "361",
    path: "survey/result/get",
    method: METHODS.post,
  },
  survey_question_vote: {
    id: "362",
    path: "survey/question/vote",
    method: METHODS.post,
  },
  survey_user_get_all: {
    id: "363",
    path: "survey/user/get/all",
    method: METHODS.post,
  },
  survey_user_answer: {
    id: "364",
    path: "survey/user/answer",
    method: METHODS.post,
  },
  survey_workspace_get_all: {
    id: "365",
    path: "survey/workspace/get/all",
    method: METHODS.post,
  },
  survey_update: {
    id: "366",
    path: "survey/update",
    method: METHODS.post,
  },
  survey_question_update: {
    id: "367",
    path: "survey/question/update",
    method: METHODS.post,
  },
  survey_question_option_update: {
    id: "368",
    path: "survey/question/option/update",
    method: METHODS.post,
  },
  survey_delete: {
    id: "369",
    path: "survey/delete",
    method: METHODS.post,
  },
  survey_question_delete: {
    id: "370",
    path: "survey/question/delete",
    method: METHODS.post,
  },
  survey_question_option_delete: {
    id: "371",
    path: "survey/question/option/delete",
    method: METHODS.post,
  },
  survey_question_create: {
    id: "372",
    path: "survey/question/create",
    method: METHODS.post,
  },
  survey_question_option_create: {
    id: "373",
    path: "survey/question/option/create",
    method: METHODS.post,
  },
  survey_create: {
    id: "374",
    path: "survey/create",
    method: METHODS.post,
  },

  social_message_get:{id:"521", path:"social/message/get", method: METHODS.post},
  social_message_create: {
    id: "522",
    path: "social/message/create",
    method: METHODS.post,
  },
  social_message_update: {
    id: "523",
    path: "social/message/update",
    method: METHODS.post,
  },
  social_message_delete: {
    id: "524",
    path: "social/message/delete",
    method: METHODS.post,
  },
  social_message_get_pinboard: {
    id: "525",
    path: "social/message/getByPinboard",
    method: METHODS.post,
  },
  social_message_comments_get: {
    id: "526",
    path: "social/message/comments/get",
    method: METHODS.post,
  },

  social_message_file_upload:{
    id:"522-upload", 
    path:"social/message/file/upload", 
    method: METHODS.post
  },
  social_user_messages_get: {
    id: "527",
    path: "social/message/getUser",
    method: METHODS.post,
  },
  social_new_messages_get: {
    id: "527a",
    path: "social/message/new",
    method: METHODS.post,
  },
  
  social_message_add_view: { id: "528", path: "social/message/shown", method: METHODS.post },
  social_like_create: {
    id: "532",
    path: "social/like/create",
    method: METHODS.post,
  },
  social_like_set: { id: "533", path: "social/like/set", method: METHODS.post },
  social_like_get: { id: "534", path: "social/like/get", method: METHODS.post },
  
  social_message_set_pinboard: {
    id: "540",
    path: "social/pin",
    method: METHODS.post,
  },
  proxy_jitsi_conference_pin_mapper: {
    id: "proxy001",
    path: "/proxy/jitsi/conference_mapper",
    method: METHODS.post,
  },
  add_support_user: {
    id: "541",
    path: "users/support/add",
    method: METHODS.post,
  },
  get_landing_page: {
    id: "601",
    path: "landing/page/get",
    method: METHODS.post
  },
  create_landing_page: {
    id: "602",
    path: "landing/page/create",
    method: METHODS.post
  },
  update_landing_page: {
    id: "603",
    path: "landing/page/update",
    method: METHODS.post
  },
  delete_landing_page: {
    id: "603",
    path: "landing/page/delete",
    method: METHODS.post
  }
};

export const RESPONSE_TYPE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  PENDING: "PENDING",
};

export function GetApiURL() {
  //console.log(process.env.REACT_APP_API_URL)
  return process.env.REACT_APP_API_URL;
  // return _API_URL_LOCAL;
  // if ((document.location + "").indexOf("dev.klar.land") > -1) {
  // return _API_URL_DEV;
  // }

  // if ((document.location + "").indexOf("d01.klar.land") > -1) {
  //   return _API_URL_STAGE;
  // }

  // if ((document.location + "").indexOf("klar.land") > -1) {
  //   return _API_URL;
  // }

  // if ((document.location + "").indexOf("localhost:3000") > -1) {
  //   return _API_URL_LOCAL;
  // }

  // if ((document.location + "").indexOf("localhost:8081") > -1) {
  //   return _API_URL_LOCAL_STAGE;
  // }
}

export async function _doRequest_async(endpoint, dispatch, data) {
  var ob = _createApiCallObject(endpoint, data);
  let timeout = 500000000;
  let s = await fetch(ob.url, ob.data, timeout);
  let responseJson = await s.json();

  if (dispatch && EndpointFunctionMap.has(endpoint)) {
    dispatch(
      EndpointFunctionMap.get(endpoint)(
        RESPONSE_TYPE.SUCCESS,
        responseJson.info,
        responseJson.data
      )
    );
    //console.log(EndpointFunctionMap.get(endpoint))
  }

  return responseJson;
}

export function _doRequest(endpoint, actionFunc, data, callBackFn) {
  let debug = false;

  var ob = _createApiCallObject(endpoint, data);

  return (dispatch) => {
    if (debug) console.log(endpoint, data, ob);

    if (endpoint.id !== "294" && endpoint.id !== "278" && endpoint.id !== "528" && endpoint.id !== "527" && endpoint.id !== "531") {
      //278=check user status||294=message_getNew
      dispatch(apiRequestStart());
      dispatch(actionFunc(RESPONSE_TYPE.PENDING, data));
    }
    let timeout = 500000000;
    if (ob.url.indexOf("/doc/update") !== -1) timeout = 260000000;
    //console.log(ob.url, ob)

    return fetch(ob.url, ob.data, timeout)
      .then((r) => {
        if (debug || endpoint.id === "402") console.log(r);

        r.json().then((res) => {
          if (r.status === 401 && res.id !== "003") {
            dispatch(auth());
          }
          if (debug) console.log(res);
          if (res.success === false) {
            if (callBackFn) {
              callBackFn(res);
            }
            console.error(res);
            //throw res;
          }
          if (res.success === true) {
            if (!res.data) res.data = {};
            if (res.token) res.data.token = res.token;

            // ! Taking it out for now as it causes too many dependencies between frontend
            //  and backend
            // if (
            //   res.info != null &&
            //   (res.id === endpoint.id || res.info.id === endpoint.id)
            // )
            dispatch(actionFunc(RESPONSE_TYPE.SUCCESS, res.info, res.data));
          } else dispatch(actionFunc(RESPONSE_TYPE.ERROR, res.info, res.data));

          if (ob.body?.socket) {
            if (res.data.msg.type === "call") {
              // Send Socker=t request to init
              //return ob.body.socket.send(JSON.stringify(res.data.msg));
            }
            if (res.data.message) {
              console.log("MESSAGE_FORMAT");
              let data = _formatGroupMessageResp(res.data.message);
              //ob.body.socket.send(JSON.stringify(data));
            } else {
              let data;
              if (res.data.msg) {
                console.log("MESSAGE_DAT_FORMAT");
                data = res.data.msg;
                data.timestamp = new Date();
                // ob.body.socket.send(JSON.stringify(res.data.msg));
              } else {
                console.log("MESSAGE_OTHER");
                data = res.data.msgs;
                data.timestamp = new Date();
                //  ob.body.socket.send(JSON.stringify(res.data.msgs));
              }
            }
          }
          return res;
        });
      })
      .catch((error) => {
        if (debug) console.log(error);
        dispatch(actionFunc(RESPONSE_TYPE.ERROR, error));
      })
      .finally(() => dispatch(apiRequestDone()));
  };
}

function _formatGroupMessageResp(msg) {
  return {
    ...msg,
    to: msg.message_group_id,
    type: msg.message_type,
    timestamp: new Date(),
    from_name: msg?.first_name + " " + msg?.lastname,
  };
}

export function _createApiCallObject(endpoint, bodydata) {
  ///console.log("store lanuage", store.getState().language.selected);

  var o = {};
  o.url = GetApiURL() + _API_VERSION;
  /*if(endpoint.id === "501")
    o.url = "http://localhost:8000/" + _API_VERSION;
  */
  o.headers = {
    "x-api-key": _API_KEY,
    "Content-Type": "application/json",
    "X-Client-URL": window?.location?.href,
    "Accept-Language": store?.getState()?.language.selected || "de",
    Authorization: "Bearer " + _getToken(),
  };

  //if(endpoint.id === "309")
  o.headers.co = GetDomain();
  o.headers.ws = GetWorkspace();

  o.method = "POST";
  o.body = {};
  __setAction(endpoint, o, bodydata);

  o.data = { headers: o.headers };
  if (o.body && Object.keys(o.body).length)
    o.data.body = JSON.stringify(o.body);
  o.data.method = o.method;
  if (o.body && o.body.get != null) {
    o.body = JSON.stringify(o.body);
    o.async = true;
    o.crossDomain = true;
    o.contentType = false;
    o.processData = false;
    //o.data = o.body;
    o.data = { headers: o.headers };
    o.data.method = o.method;
    o.redirect = "follow";

    //o.body = null;
    //	o.mimeType = "multipart/form-data";
  }

  return o;
}

export function _createActionResult(scope, type, info, payload) {
  return {
    scope: scope,
    type: type,
    info: info != null ? info : null,
    payload: payload != null ? payload : null,
  };
}

function __setAction(endpoint, o, bodydata) {
  o.url += endpoint.path;
  o.method = endpoint.method;
  o.redirect = "follow";
  // eslint-disable-next-line
  // console.log(endpoint);
  if (endpoint.path === Endpoints.public_data.path) {
    if (bodydata.company) o.url += CONST._slash + bodydata.company;
    if (bodydata.workspace) o.url += CONST._slash + bodydata.workspace;
    //    console.log(o.url, bodydata);
    //o.url
  }
  if (_API_TOKEN != null && _API_TOKEN.length > 4)
    o.url += "?nocache=" + _API_TOKEN.slice(-3);
  else o.url += "?nocache=" + new Date().getTime() / 1000;

  o.body = bodydata;
  if (o.body == null) o.body = {};
  if (endpoint.path === Endpoints.public_data.path) o.body = null;
}

export function _getToken() {
  if (_API_TOKEN == null || _API_TOKEN.length === 0)
    _API_TOKEN = GetLocalItem(CONST._cookies.token);
  return _API_TOKEN;
}

export function _setToken(token) {
  _API_TOKEN = token;
  SetLocalItem(CONST._cookies.token, _API_TOKEN);
}


export function GetAuthHeader(){
  const header = {};
  header["Authorization"] = "Bearer " + _getToken();
  header["x-api-key"] = _API_KEY;
  header["X-Client-URL"] = window?.location?.href;
  header["Accept-Language"] = store?.getState()?.language.selected || "de";
  return header;
}