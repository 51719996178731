import { CONST } from "../../Constants";

export class PublicData {
  constructor(payload) {
    this.company = CONST._empty;
    this.workspace = CONST._empty;
    //this.data = {login:{title:{de:'login'}, text:{de:'loading'}}, logo:'/images/klarland/logo_white_black.png', url:''};

    this.login = {};
    this.login.title = "login";
    this.login.text = "loading";
    this.logo = "/images/klarland/logo_white_black.png";
    this.bg = "/images/klarland/bg.jpg";
    this.thumb = "/images/klarland/default.png";
    this.url = "";
    this.company_id = '';
    this.workspace_id = '';

    if (payload) {
      this.company = payload.company ? payload.company : CONST._empty;
      this.workspace = payload.workspace ? payload.workspace : CONST._empty;
      this.login = {};
      this.login.title = payload.data?.title;
      this.login.text = payload.data?.text;
      if (payload.data.url) this.url = payload.data.url;
      if (payload.data.logo) this.logo = payload.data.url + payload.data.logo;
      if (payload.data.bg) this.bg = payload.data.url + payload.data.bg;
      if (payload.data.thumb) this.thumb = payload.data.url + payload.data.thumb;
      if (payload.data.company_id){
        this.company_id = payload.data.company_id;
      }
      if (payload.data.workspace_id){
        this.workspace_id = payload.data.workspace_id;
      }
    }
  }

  GetLoginTitle() {
    return this.login.title;
  }

  GetLoginText() {
    return this.login.text;
  }

  GetLogo() {
    return this.logo;
  }

  GetBackground() {
    return this.bg;
  }
  GetThumb() {
    return this.thumb;
  }
}
