import { CONST } from "Constants";

import { io } from "socket.io-client";


import {store} from "../";
import { start as startCall, accept as acceptCall, reject as rejectCall, hangup as hangUpCall } from "../_redux/api/call";

import {Init as GroupInit} from "./GroupWebsocket";
import {Init as RemoteInit} from "./RemoteWebsocket";
import {Init as SurveyInit} from "./SurveyWebsocket";


let socket = null;
let user_id=null;
let token = null;

let listeners = {};
let wsQueue = [];
export function addListener(type, listener)
{
  if(listeners[type] == null)
    listeners[type] = [];
  if(!listeners[type].includes(listener))
    listeners[type].push(listener);
}

export function removeListener(type, listener)
{
  
  if(listeners[type] != null && listeners[type].includes(listener))
    listeners[type].splice(listeners[type].indexOf(listener), 1);
}

export function SendRaw(rawMsg)
{
  if(!socket || socket.readyState!=1)
    return;
  
  rawMsg.token =  token;
  socket.send(
    JSON.stringify(rawMsg)
  );
}

export function SendWsMessage(user, msg, type=CONST.messagetypes.text)
{
  SendSocketIoRawMessage("sendMessage",{uuid:user.uuid, type:type, msg:msg})  
}

export function SendSocketIoRawMessage(eventname, data)
{
  if(!socket || !socket.connected){
    const alreadyInQueue = wsQueue.find((e,i)=>{
      if(e.eventname === eventname && JSON.stringify(data) === JSON.stringify(e.data))
        return true;
      return false;
    })
    
    if(!alreadyInQueue)
      wsQueue.push({eventname, data});
    
    return false;
  }
  
  socket.emit(eventname, data);
  
}


export function GetSocket()
{
  return socket;
}

export function Init(_user_id, _token)
{ 
  user_id = _user_id;
  token = _token;
  socket = io(process.env.REACT_APP_WS_URL, {
        extraHeaders: {authorization: "Bearer"+ " "+token},
        closeOnBeforeunload: false
    });

  socket.on(Events.error, (error)=>{
    console.log("Websocket erorr, not connected", error);
    notifyListeners(Events.connectionState, false);    
  });
  socket.on(Events.connect, () => {    
    console.log("Websocket connected");
  
    if(wsQueue.length>0){
      console.log("Sending Websocket queue", wsQueue);
      for(let i=0; i<wsQueue.length;i++){        
        SendSocketIoRawMessage(wsQueue[i].eventname, wsQueue[i].data)
      }
    }
    notifyListeners(Events.connectionState, true);
  });
  socket.on(Events.disconnect, () => {  
         // console.log("ConnectedUser", data)
    notifyListeners(Events.connectionState, false);        
  });
  
  socket.on(Events.sendMessage, (data) => {
    notifyListeners(Events.sendMessage, data);
  });

  socket.on(Events.ConnectedUser, (data)=>{
     notifyListeners(Events.ConnectedUser, data);      
  })

  socket.on(Events.ConnectedUsers, (data)=>{
    notifyListeners(Events.ConnectedUsers, data);   
  })
    
  socket.on(Events.call_start, (data)=>{
    store.dispatch(startCall(data));
    notifyListeners(Events.call_start, data)   
  });
  socket.on(Events.call_accept, (data)=>{
    store.dispatch(acceptCall(data));
    notifyListeners(Events.call_accept, data)    
    
  });
  socket.on(Events.call_reject, (data)=>{
    store.dispatch(rejectCall(data));
    notifyListeners(Events.call_reject, data)    
  });
  socket.on(Events.call_hangup, (data)=>{
    store.dispatch(hangUpCall(data));
    notifyListeners(Events.call_hangup, data)   
  });

  GroupInit(socket);
  RemoteInit(socket, user_id);
  SurveyInit(socket, user_id);
  
  
}

export function notifyListeners(event, data){
  
  if(listeners[event])
    listeners[event].map(l=>l(data));

  if(listeners[Events._EVERY])
    listeners[Events._EVERY].map(l=>l(event, data));
}

export const Events = {
  _EVERY:'_EVERY',

  
  error:'error',
  connect:'connect',
  disconnect:'disconnect',
  connectionState:'connectionState',
  sendMessage:'sendMessage',
  ConnectedUser:'ConnectedUser',
  ConnectedUsers:'ConnectedUsers',

  call_start:'calling',
  call_accept:'acceptCall',
  call_reject:'rejectCall',
  call_hangup:'hangUpCall',

}