import { CONST } from "Constants";
import { DateTime } from "luxon";

export function ParseEntries(raw) {

  let entries = [];
  if(raw == null)
    return entries;
  if (Array.isArray(raw)) {
    for (let i in raw) {
      let entry = ParseEntry(raw[i]);
      if (entry)entries.push(entry);
      
    }
  } else {
    let entry = ParseEntry(raw);
    
    if (entry) entries.push(entry);
  }
  return entries;
}
export function ParseEntry(raw) {
  if(raw == null || (raw.id==null && raw.type==null))
    return null;
  try {
    let entry = new AnalyticsEntry();
    entry.name = raw.name;
    entry.path = raw.path;
    entry.type = raw.type;
    entry.id = raw.id;
    entry.duration = raw.duration;
    entry.details = raw.data;
    entry.clicks = raw.clicks;
    if(raw.entries)
      entry.entries = ParseEntries(raw.entries);
    
    if(raw.ts){
      entry.ts = DateTime.fromISO(raw.ts);
    }
    if(raw.start){
      entry.ts = DateTime.fromISO(raw.start);
    }
    if(!entry.ts)
      entry.ts = DateTime.fromSeconds(0);
    return entry;
  } catch (e) {
    console.error("Error parsing entry", raw);
  }
  return null;
}

export class AnalyticsEntry {
  constructor() {
    this.id = null;
    this.name = CONST._empty;
    this.type = null;
    this.duration = 0;
    this.path = null;
    this.details = null;
    this.clicks = 0;
    this.entries = [];
    this.ts = DateTime.fromSeconds(0);
  }
}

AnalyticsEntry.Types = {
  register_event: "register_event",
  meeting: "meeting",
  video: "video",
  img: "img",
  pdf: "pdf",
  page:'page',
  stage:'stage',
  workspace:'workspace',
  _3d:'3d',
  view:'view'

};


