import {
  CALENDAR_GET,
  CALENDAR_ADD,
  CALENDAR_UPDATE,
  CALENDAR_DELETE,
  CALENDAR_ADD_MEETING,
} from "./../actions/calendar";
import { APP_LOGOUT_USER } from "./../actions/app";
import { initialState } from "initialState.js";

export function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case APP_LOGOUT_USER:
      return { ...state, entry: null };
    case CALENDAR_GET.SUCCESS: {
      let calendar = action.payload.calendar;

      return { ...state, pending: false, entry: calendar };
    }
    case CALENDAR_ADD.SUCCESS: {
      let calendar = action.payload.calendar;
      return { ...state, pending: false, entry: calendar };
    }
    case CALENDAR_ADD.ERROR: {
      // let calendar =  action.payload.calendar;
      // return {...state,pending: false,entry:calendar}
      return state;
    }
    case CALENDAR_DELETE.SUCCESS: {
      let calendar = action.payload.calendar;
      return { ...state, pending: false, entry: calendar };
    }
    case CALENDAR_DELETE.ERROR: {
      // console.log(action.payload);
      return state;
    }
    case CALENDAR_UPDATE.SUCCESS: {
      let calendar = action.payload.calendar;
      return { ...state, pending: false, entry: calendar };
    }
    case CALENDAR_ADD_MEETING.SUCCESS: {
      // console.log(action.payload);
      return state;
    }
    case CALENDAR_ADD_MEETING.ERROR: {
      console.log(action.payload);
      return state;
    }

    default:
      return state;
  }
}
//export const IsError = state => state.calendar.error;
//export const IsPending = state => state.calendar.pending;
export const GetCalendar = (state) => state.calendar.entry;
