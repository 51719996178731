import { SendSocketIoRawMessage, notifyListeners } from "./Websocket";

//eslint-disable-next-line
let user_id = null;
export function Init(socket, _user_id) {
  user_id = _user_id;

  socket.on(Events.surveyUpdated, (data) => {
    //if(user_id === data.sharer_id)
    //  store.dispatch(_setActiveGroup(data.workspace_id));
    console.log(data, Events.surveyUpdated);
    notifyListeners(Events.surveyUpdated, data);
  });

  socket.on(Events.share, (data) => {});

  socket.on(Events.joinSurveyGroup, (data) => {
    notifyListeners(Events.joinSurveyGroup, data);
  });

  socket.on(Events.pingSurveyGroup, (data) => {
    notifyListeners(Events.pingSurveyGroup, data);
  });
}

export function Update(workspace_id, msg) {
  SendSocketIoRawMessage(Events.surveyUpdated, {
    group_id: workspace_id,
    msg: msg,
  });
}

export function Share(workspace_id, msg) {
  //SendSocketIoRawMessage(vents.___share,{workspace_id:workspace_id, msg:msg})
}

export function Join(workspace_id) {
  SendSocketIoRawMessage(Events.joinSurveyGroup, {
    uuid: workspace_id,
  });
}

export function Ping(workspace_id, pingType) {
  SendSocketIoRawMessage(Events.pingSurveyGroup, {
    uuid: workspace_id,
    pingType: pingType,
  });
}

export const Events = {
  ___share: "...",
  surveyUpdated: "surveyUpdated",
  joinSurveyGroup: "joinSurveyGroup",
  pingSurveyGroup: "pingSurveyGroup",
};

export const PingTypes = {
  questionStateChanged: "questionStateChanged",
  questionAnswered: "questionAnswered",
  surveyDeleted: "surveyDeleted",
  surveyQuestionDeleted: "surveyQuestionDeleted",
};
