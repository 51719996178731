import {
  MESSAGE_GET_PENDING,
  MESSAGE_GET_SUCCESS,
  MESSAGE_GET_ERROR,
  MESSAGE_GET_NEW_PENDING,
  MESSAGE_GET_NEW_SUCCESS,
  MESSAGE_GET_NEW_ERROR,
  MESSAGE_CREATE_ERROR,
  MESSAGE_CREATE_PENDING,
  MESSAGE_CREATE_SUCCESS,
  MESSAGE_SETSHOWN_ERROR,
  MESSAGE_SETSHOWN_PENDING,
  MESSAGE_SETSHOWN_SUCCESS,
  MESSAGE_SEND_FILE_ERROR,
  MESSAGE_SEND_FILE_SUCCESS,
  MESSAGE_SEND_FILE_PENDING,
  GET_GROUP_PENDING,
  GET_GROUP_SUCCESS,
  GET_GROUP_ERROR,
  SEND_GROUP_MESSAGE_PENDING,
  SEND_GROUP_MESSAGE_ERROR,
  SEND_GROUP_MESSAGE_SUCCESS,
  GET_GROUP_MESSAGES_SUCCESS,
  GET_GROUP_MESSAGES_PENDING,
  GET_GROUP_MESSAGES_ERROR,
  CREATE_GROUP_PENDING,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  GET_USER_GROUPS_PENDING,
  GET_USER_GROUPS_SUCCESS,
  GET_USER_GROUPS_ERROR,
  MESSAGE_ADD_FROM_WS,
  MESSAGE_ADD_NEW,
  CLEAR_GROUP_INFO,
} from "./../actions/message";

import { initialState } from "initialState.js";
import { MessagesDB, ParseGroupMessage } from "./../_models/MessagesDB";
import { DynamicSort } from "../../KlarHelper";

let messagesDB = new MessagesDB();
export function messageReducer(state = initialState, action) {
  switch (action.type) {
    /*#region MESSAGE_GET*/
    case MESSAGE_GET_SUCCESS: {
      // console.log(action.payload);
      if (action.payload.msgs && Array.isArray(action.payload.msgs)) {
        //console.log('msg=====',action.payload)
      }
      //console.log(action.payload)
      messagesDB.ResetMessage();
      messagesDB.SetMessages(action.payload.msgs, action.payload.user);
      return {
        ...state,
        pending: false,
        stateHash: messagesDB.GetHash(),
        /*: messagesDB.GetCall(),*/
      };
    }
    case MESSAGE_GET_PENDING:
      return { ...state, error: null, pending: true };
    case MESSAGE_GET_ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion MESSAGE_GET*/

    /*#region MESSAGE_GET_NEW*/
    case MESSAGE_GET_NEW_SUCCESS: {
      let lastLoadedMessageId = state.lastLoadedMessageId;
      if (action.payload.msgs && Array.isArray(action.payload.msgs)) {
        action.payload.msgs.map((msg) => {
          if (msg.id > lastLoadedMessageId) lastLoadedMessageId = msg.id;
          messagesDB.AddMessage(msg, msg.from);
          return false;
        });
      }
      if (lastLoadedMessageId === state.lastLoadedMessageId) return state;

      return {
        ...state,
        pending: false,
        stateHash: messagesDB.GetHash(),
        lastLoadedMessageId: lastLoadedMessageId,
        /*call: messagesDB.GetCall(),*/
      };
    }
    case MESSAGE_GET_NEW_PENDING:
      return { ...state, error: null, pending: false };
    case MESSAGE_GET_NEW_ERROR:
      return { ...state, pending: false, error: action.info };
    case GET_GROUP_MESSAGES_SUCCESS:
      // TODO this needs to be implemented in order to add only new messages, see above
      // if (msg.id > lastLoadedMessageId) lastLoadedMessageId = msg.id;
      let group_messages = [];
      if (action.payload.message && Array.isArray(action.payload.message)) {
        action.payload.message.sort(DynamicSort("created_at"));
        action.payload.message.map((msg) => {
          messagesDB.AddMessage(msg, msg.message_group_id);
          group_messages.push(ParseGroupMessage(msg));
        });
      }
      return {
        ...state,
        pending: false,
        stateHash: messagesDB.GetHash(),
        groupMessages: group_messages,
      };
    case GET_GROUP_MESSAGES_PENDING:
      return { ...state, error: null, pending: true };
    case GET_GROUP_MESSAGES_ERROR:
      return { ...state, pending: false, error: action.info };

    /*#endregion MESSAGE_GET_NEW*/

    /*#region MESSAGE_CREATE*/
    case MESSAGE_CREATE_SUCCESS: {
      if (action.payload.msg)
        messagesDB.AddMessage(action.payload.msg, action.payload.msg.to);

      return { ...state, pending: false, stateHash: messagesDB.GetHash() };
    }
    case MESSAGE_ADD_FROM_WS: {
      if (action.data) {
        if (action.isGroup) {
          if (action.data) {
            action.data.is_new = true;
            action.data.user = {
              firstname: action.data.first_name,
              lastname: action.data.lastname,
            };
          }
          messagesDB.AddMessage(action.data, action.data.message_group_id);
        } else messagesDB.AddMessage(action.data, action.data.from);
      }

      return { ...state, pending: false, stateHash: messagesDB.GetHash() };
    }
    case MESSAGE_ADD_NEW: {
      let msg = action.data;
      msg["internal"] = true;
      if (action.data) messagesDB.AddMessage(msg, action.data.to);

      return { ...state, pending: false, stateHash: messagesDB.GetHash() };
    }
    case MESSAGE_CREATE_PENDING:
      return { ...state, error: null, pending: true };
    case MESSAGE_CREATE_ERROR:
      return { ...state, pending: false, error: action.info };
    case SEND_GROUP_MESSAGE_PENDING:
      return { ...state, error: null, pending: true };
    case SEND_GROUP_MESSAGE_ERROR:
      return { ...state, pending: false, error: action.info };
    case SEND_GROUP_MESSAGE_SUCCESS: {
      if (action.payload.msg)
        messagesDB.AddMessage(
          action.payload.message,
          action.payload.message.message_group_id
        );

      return { ...state, pending: false, stateHash: messagesDB.GetHash() };
    }
    /*#endregion MESSAGE_CREATE*/
    /*#region MESSAGE_SETSHWON*/
    case MESSAGE_SETSHOWN_SUCCESS: {
      if (action.payload.msgs && Array.isArray(action.payload.msgs)) {
        action.payload.msgs.map((msg) => messagesDB.AddMessage(msg, msg.from));
      }
      return { ...state, pending: false, stateHash: messagesDB.GetHash() };
    }
    case MESSAGE_SETSHOWN_PENDING:
      return { ...state, error: null, pending: true };
    case MESSAGE_SETSHOWN_ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion MESSAGE_SETSHWON*/

    case MESSAGE_SEND_FILE_SUCCESS:
      if (action.payload.url != null) {
        return {
          ...state,
          uploadedFile: {
            url: action.payload.url,
            uuid: action.payload.fileuuid,
          },
        };
      }
      console.log("SHOULD BE NEVER CALLED BECAUSE DEPREACTED");
    //to endpijtn return just a url of the uploaded file
    //there ios nohjn mor message crewation action - this is movved to the socket io server
    /*if (action.payload.msgs) {
        messagesDB.AddMessage(action.payload.msgs, action.payload.msgs.to);
      }
      return {
        ...state,
        pending: false,
        stateHash: messagesDB.GetHash(),
        msg: action.payload.msgs.msg,
        uploadedFileResp: action.payload,
      };*/
    case MESSAGE_SEND_FILE_PENDING:
      return { ...state, error: null, pending: true };
    case MESSAGE_SEND_FILE_ERROR:
      console.log(action.payload);
      return { ...state, pending: false, error: action.info };
    case GET_GROUP_PENDING:
      return { ...state, error: null, pending: true };
    case GET_GROUP_SUCCESS:
      return { ...state, pending: false, group: action.payload.group };
    case GET_GROUP_ERROR:
      return { ...state, pending: false, error: action.info };
    case CREATE_GROUP_PENDING:
      return { ...state, error: null, pending: true };
    case CREATE_GROUP_SUCCESS:
      return { ...state, pending: false, created_group: action.payload.group };
    case CREATE_GROUP_ERROR:
      return { ...state, pending: false, error: action.info };
    case GET_USER_GROUPS_PENDING:
      return { ...state, error: null, pending: true };
    case GET_USER_GROUPS_SUCCESS:
      let groups = action.payload.group;
      for (let key in groups) {
        groups[key].uuid = groups[key].message_group_id;
      }
      return { ...state, pending: false, groups: action.payload.group };
    case GET_USER_GROUPS_ERROR:
      return { ...state, pending: false, error: action.info };
    case CLEAR_GROUP_INFO:
      return { ...state, group: null };
    default:
      return state;
  }
}

export const __IsError = (state) => state.messages.error;
export const __IsPending = (state) => state.messages.pending;
export const __GetAvailable = (state) => state.messages.availableWorkspaces;
export const AllUserGroups = (state) => state.messages.groups;
export const GetMessagesHash = (state) => state.messages.stateHash;
export const GetLastLoadedMessageId = (state) =>
  state.messages.lastLoadedMessageId;
/*export const GetCall = (state) =>{ 
 return state.messages.call
};*/
export const UploadedFile = (state) => state.messages.uploadedFile;

//export const GetActiveCall = (state) => state.messages.call;
export const GetGroupInformation = (state) => state.messages.group;
export const GroupMessages = (state) => state.messages.groupMessages;

export function GetNewMessages(email = null) {
  return messagesDB.GetNewMessages(email);
}

export function GetMessagesById(id) {
  return messagesDB.GetMessagesList(id);
}

export const GetAllEmail = () => {
  const emailList = [];
  messagesDB.messages?.forEach((value, key) => {
    emailList.push(key);
  });
  return emailList;
};
