
import {GenericAction} from './../actions/api.js';

/*

export const MEETING_GET={
  PENDING: '',
  SUCCESS: '',
  ERROR: '',
}
*/
export const USERS_GET={PENDING: 'USERS_GET_PENDING',SUCCESS: 'USERS_GET_SUCCESS',ERROR: 'USERS_GET_ERROR'}
export const USERS_CREATE={PENDING: 'USERS_CREATE_PENDING',SUCCESS: 'USERS_CREATE_SUCCESS',ERROR: 'USERS_CREATE_ERROR'}
export const USERS_GET_AVAILABLE={PENDING: 'USERS_GET_AVAILABLE_PENDING',SUCCESS: 'USERS_GET_AVAILABLE_SUCCESS',ERROR: 'USERS_GET_AVAILABLE_ERROR'}
export const USERS_DELETE={PENDING: 'USERS_DELETE_PENDING',SUCCESS: 'USERS_DELETE_SUCCESS',ERROR: 'USERS_DELETE_ERROR'}
export const USERS_SEARCH={PENDING: 'USERS_SEARCH_PENDING',ERROR: 'USERS_SEARCH_ERROR'}
export const USERS_SEARCH_FAST_SUCCESS = 'USERS_SEARCH_FAST_SUCCESS';
export const USERS_SEARCH_DETAILED_SUCCESS = 'USERS_SEARCH_DETAILED_SUCCESS';
export const USERS_INVITATE={PENDING: 'USERS_INVITATE_PENDING',SUCCESS: 'USERS_INVITATE_SUCCESS',ERROR: 'USERS_INVITATE_ERROR'}
export const USERS_STATUS={PENDING: 'USERS_STATUS_PENDING',SUCCESS: 'USERS_STATUS_SUCCESS',ERROR: 'USERS_STATUS_ERROR'}
export const USER_SET_STATUS="USER_SET_STATUS";

export const ADD_SUPPORT_USER = {
  PENDING: "ADD_SUPPORT_USER_PENDING",
  SUCCESS: "ADD_SUPPORT_USER_SUCCESS",
  ERROR: "ADD_SUPPORT_USER_ERROR",
};

export function _get(type, info, payload) {  
  return {type: USERS_GET[type],info: info,payload: payload};	
}
export function _getAvailable(type, info, payload) {  
  return {type: USERS_GET_AVAILABLE[type],info: info,payload: payload};	
}
export function _add(type, info, payload) {  
  return {type: USERS_CREATE[type],info: info,payload: payload};	
}
export function _deny(type, info, payload) {  
  return {type: USERS_DELETE[type],info: info,payload: payload};	
}
export function searchFast(type, info, payload) {
	return GenericAction(type, info, payload, USERS_SEARCH.PENDING, USERS_SEARCH_FAST_SUCCESS, USERS_SEARCH.ERROR);
}
export function searchDetailed(type, info, payload) {
	return GenericAction(type, info, payload, USERS_SEARCH.PENDING, USERS_SEARCH_DETAILED_SUCCESS, USERS_SEARCH.ERROR);
}
export function createInvitation(type, info, payload) {  
  return {type: USERS_INVITATE[type],info: info,payload: payload};	
}
export function getStatus(type, info, payload) {  
  return {type: USERS_STATUS[type],info: info,payload: payload};	
}

export const addSupportUser = (type, info, payload) => {
  return { type: ADD_SUPPORT_USER[type], info, payload };
};

export const SetUserState = (uuid, state) => {
  return { type: USER_SET_STATUS, uuid, state };
};
