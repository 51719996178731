import {
	USERS_GET, 
	USERS_GET_AVAILABLE, 
	USERS_SEARCH, USERS_SEARCH_FAST_SUCCESS, USERS_SEARCH_DETAILED_SUCCESS,
	USERS_CREATE,
  USERS_DELETE,
  USERS_INVITATE,
  USERS_STATUS,
	USER_SET_STATUS

} from './../actions/users';

import {initialState}  from 'initialState.js';
import {DynamicSort} from 'KlarHelper';
import {User} from './../_models/User';
import {UserDB} from './../_models/UserDB';


let userDB = new UserDB();

export function usersReducer(state = initialState, action) {	
	switch(action.type) {
    case USERS_INVITATE.SUCCESS:
    {
      return {
        ...state,			
        error: null,
        pending: false,
        invitation:action.payload.invitation
      }
    }
		/*#region USERS_DELETE (deny)*/
		case USERS_DELETE.PENDING:
      return {...state, error: null, pending: true};      
		case USERS_DELETE.SUCCESS:	
		{
			let users = state.foundDetailed;
			for(let j in users)
			{				
				if(action.payload.user.email === users[j].email)
				{					
					users.splice(j,1);
					break;
				}
			}		
			userDB.SetUsers(users);
			return {...state,pending: false,foundDetailed:users} 
		}
		case USERS_DELETE.ERROR:					
		return {...state,pending: false,error: action.info}
		/*#endregion USERS_DELETE (deny)*/

		/*#region USERS_CREATE (connect)*/
		case USERS_CREATE.PENDING: 
			return {...state,error: null,pending: true}
		case USERS_CREATE.SUCCESS:	
			let found = false;
			
			let users3 = state.list;
			for(let j in users3)
			{				
				if(action.payload.user.email === users3[j].email)
				{					
					users3[j] = User.ParseOtherUser(action.payload.user)
					found = true;
					break;
				}
			}
			if(!found)
			{
				users3.push(User.ParseOtherUser(action.payload.user))		
				users3.sort(DynamicSort('connectionType'));
			}

			userDB.SetUsers(users3);

			return {
				...state,				
				pending: false,
				list:users3,
			}
		case USERS_CREATE.ERROR:					
			return {...state,pending: false,error: action.info}
		/*#endregion USERS_CREATE (connect)*/

		/*#region USERS_GET*/
		case USERS_GET.PENDING: 
			return {...state,error: null,pending: true}
		case USERS_GET.SUCCESS:	
			
			return {
				...state,				
				pending: false,
				messages: []
			}
		case USERS_GET.ERROR:					
			return {...state,pending: false,error: action.info}
		/*#endregion USERS_GET*/

    case USERS_STATUS.SUCCESS:
    {
     
     for(let i in action.payload)
     {
      if(!action.payload[i] || !action.payload[i].email || !action.payload[i].val)
        continue;
     // if(action.payload[i].email === 'curt@levriver.com')
      //  console.log(action.payload, state.list);
      for(let j in state.list)
      {
        if(state.list[j].email === action.payload[i].email)
          state.list[j].status = action.payload[i].val;
      }
      userDB.UpdateStatus(action.payload[i].email, action.payload[i].val);
     }
			return{
				...state,				
				pending: false,
				list:[...state.list],
			}
    }

		/*#region USERS_GET_AVAILABLE*/
			case USERS_GET_AVAILABLE.PENDING: 
			return {...state,error: null,pending: true}
		case USERS_GET_AVAILABLE.SUCCESS:	
			let users = [];
			for(let i in action.payload.users)
				users.push(User.ParseOtherUser(action.payload.users[i]))
			
			users.sort(DynamicSort('connectionType'));
			userDB.SetUsers(users);
			
			return {
				...state,				
				pending: false,
				list:users,
			}
  	case USERS_GET_AVAILABLE.ERROR:					
			return {...state,pending: false,error: action.info}
		/*#endregion USERS_GET_AVAILABLE*/

  	/*#region USERS_SEARCH_AVAILABLE*/
    case USERS_SEARCH.PENDING: 
      return {...state,error: null,pending: true}
    case USERS_SEARCH_FAST_SUCCESS:	
      
      let users1 = [];
      for(let i in action.payload.users)
      {
        let user = User.Parse(action.payload.users[i]);
        userDB.AddUser(user);
        users1.push(user)
      }
      //	users.sort(DynamicSort('firstname'));
      return {...state,pending: false,foundFast:users1,needle: action.payload.needle}
    case USERS_SEARCH_DETAILED_SUCCESS:	
    
      let users2 = [];
      for(let i in action.payload.users)
      {
        let user = User.Parse(action.payload.users[i]);
      
        userDB.AddUser(user);
        
        users2.push(user)
      }
      //	users.sort(DynamicSort('firstname'));
      return {...state,pending: false,foundDetailed:users2,needle: action.payload.needle}
    case USERS_SEARCH.ERROR:					
      return {...state,pending: false,error: action.info}
    /*#endregion USERS_SEARCH_AVAILABLE*/
		case USER_SET_STATUS:
			let user =  state.list.find((user)=>{return action.uuid === user.uuid});
			if(user){
				user.status = action.state;
				user =  userDB.FindUserByUuid(action.uuid);
				if(user)
					user.status = action.state;				
			}
			return {...state, list:[...state.list], pending: false, error: false}			
		default: 
			return state;
	}
}

export const __IsError = state => state.users.error;
export const IsPending = state => state.users.pending;
export const GetUsers = state => state.users.list;

export const GetLastLoadedMessageId = state => state.users.lastLoadedMessageId;

export const GetFoundFastUsers = state => {return {users:state.users.foundFast, needle:state.users.needle}};
export const GetFoundDetailedUsers = state => {return {users:state.users.foundDetailed, needle:state.users.needle}};

export const GetCreatedInvitation = state => state.users.invitation;

export function AddUser(user)
{
  return userDB.AddUser(user);
}
export function FindUserByEmail(email){
	return userDB.FindUserByEmail(email);
};
export function FindUserByUuid(email){
	return userDB.FindUserByUuid(email);
};

export const IsUserOnline = state => (needle)=>{
	let user =  state.users.list.find((user)=>{return user.uuid === needle || user.email === needle});
	return user?.status;
};
