import {
  MEETING_GET,
  MEETING_UPDATE,
  MEETING_CREATE,
  MEETING_GET_AVAILABLE,
  MEETING_GET_USER_BY_STREAM,
  MEETING_DELETE,
  MEETING_GET_USERS,
  MEETING_SET_USERS,
  MEETING_GET_USERS1,
  MEETING_SET_RESPONSE,
  RESET_CURRENT_MEETING,
} from "./../actions/meeting";

import { CALL_HANGEDUP, CLOSE_BROADCAST } from "./../actions/call";

import { AddUser } from "./users";
import { User } from "./../_models/User";
import { APP_LOGOUT_USER } from "./../actions/app";
import { initialState } from "initialState.js";
import { ParseMeetings, ParseMeeting } from "_redux/_models/Meeting";
import { CONST } from "../../Constants";

export function meetingReducer(state = initialState, action) {
  switch (action.type) {
    case APP_LOGOUT_USER:
      return {
        ...state,
        availableMeetings: [],
        actualMeeting: null,
      };
    case CALL_HANGEDUP: {
      return {
        ...state,
        pending: false,
        actualMeeting: null,
        lastEndedCall: action.payload.uuid,
      };
    }
    case CLOSE_BROADCAST: {
      return {
        ...state,
        pending: false,
        actualMeeting: null,
      };
    }
    /*#region MEETING_SET_USERS*/
    case MEETING_GET_USERS1.SUCCESS:
    //goto next
    case MEETING_SET_USERS.SUCCESS: {
      let users = [];
      for (let i in action.payload.users) {
        let user = User.Parse(action.payload.users[i]);
        user = AddUser(user);
        users.push(user);
      }
      let mods = [];
      for (let i in action.payload.mods) {
        let user = User.Parse(action.payload.mods[i]);
        user = AddUser(user);
        mods.push(user);
      }

      let usersDb = state.users;
      if (!usersDb) usersDb = {};
      usersDb[action.payload.meeting_id] = {
        meeting_id: action.payload.meeting_id,
        users: users,
        mods: mods,
      };

      return {
        ...state,
        pending: false,
        users: usersDb,
      };
    }
    case MEETING_GET_USERS1.ERROR:
    //goto next
    case MEETING_SET_USERS.ERROR: {
      return state;
    }
    /*#endregion MEETING_GET_USERS*/

    /*#region MEETING_GET_USERS*/
    case MEETING_GET_USERS.SUCCESS: {
      let users = [];

      for (let i in action.payload.users) {
        let user = User.Parse(action.payload.users[i]);
        user = AddUser(user);
        users.push(user);
      }
      return {
        ...state,
        pending: false,
        meetingUsers: users,
      };
    }
    case MEETING_GET_USERS.ERROR: {
      return state;
    }
    /*#endregion MEETING_GET_USERS*/

    /*#region MEETING_GET*/

    case MEETING_GET_USER_BY_STREAM.SUCCESS:
      //console.log(userNames);
      let userNames = state.userNames;
      userNames[action.payload.stream_id] = action.payload.user;
      return {
        ...state,
        pending: false,
        userNames: { ...userNames },
      };
    case MEETING_GET_USER_BY_STREAM.PENDING:
      return { ...state, error: null, pending: true };
    case MEETING_GET_USER_BY_STREAM.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion MEETING_GET*/

    /*#region MEETING_DELETE*/
    case MEETING_DELETE.SUCCESS: {
      let workspaceMeetings = state.workspaceMeetings;
      if (action.payload.meeting) {
        for (let i in workspaceMeetings) {
          if (workspaceMeetings[i].uuid === action.payload.meeting.uuid) {
            workspaceMeetings.splice(i, 1);
            break;
          }
        }
      }
      return {
        ...state,
        workspaceMeetings: [...workspaceMeetings],
      };
    }
    case MEETING_DELETE.PENDING:
      return { ...state, error: null, pending: true };
    case MEETING_DELETE.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion MEETING_DELETE*/

    /*#region MEETING_UPDATE*/
    case MEETING_UPDATE.SUCCESS: {
      let workspaceMeetings = state.workspaceMeetings;
      if (action.payload.meeting) {
        for (let i in workspaceMeetings) {
          if (workspaceMeetings[i].uuid === action.payload.meeting.uuid) {
            workspaceMeetings[i] = ParseMeeting(action.payload.meeting);
            break;
          }
        }
      }
      return {
        ...state,
        workspaceMeetings: [...workspaceMeetings],
      };
    }
    case MEETING_UPDATE.PENDING:
      return { ...state, error: null, pending: true };
    case MEETING_UPDATE.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion MEETING_UPDATE*/
    case "USER_IS_HOST":
      return { ...state, isHost: true };
    case "SET_LOBBY":
      return { ...state, set_lobby: action.payload };

    /*#region MEETING_CREATE*/

    case MEETING_CREATE.SUCCESS: {
      console.log(action.payload.meeting);
      let workspaceMeetings = state.workspaceMeetings;
      if (action.payload.meeting && action.payload.meeting.workspace_id) {
        workspaceMeetings.push(ParseMeeting(action.payload.meeting));
      }

      return {
        ...state,
        workspaceMeetings: [...workspaceMeetings],
      };
    }
    case MEETING_CREATE.PENDING:
      return { ...state, error: null, pending: true };
    case MEETING_CREATE.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion MEETING_CREATE*/

    /*#region MEETING_GET*/

    case MEETING_GET.SUCCESS:
      let actualMeeting = action.payload.meeting;
      return {
        ...state,
        pending: false,
        actualMeeting: actualMeeting,
      };
    case MEETING_GET.PENDING:
      return { ...state, error: null, pending: true };
    case MEETING_GET.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion MEETING_GET*/

    /*#region MEETING_GET_AVAILABLE*/

    case MEETING_GET_AVAILABLE.SUCCESS:
      let workspaceMeetings = state.workspaceMeetings;

      if (action.payload.workspace_meetings) {
        workspaceMeetings = ParseMeetings(action.payload.workspace_meetings);
      }
      let meetings = state.availableMeetings;
      if (action.payload.meetings)
        meetings = ParseMeetings(action.payload.meetings);

      return {
        ...state,
        pending: false,
        availableMeetings: meetings,
        workspaceMeetings: workspaceMeetings,
      };
    case MEETING_GET_AVAILABLE.PENDING:
      return { ...state, error: null, pending: true };
    case MEETING_GET_AVAILABLE.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion MEETING_GET_AVAILABLE*/

    case MEETING_SET_RESPONSE.SUCCESS:
      let meeting = action.payload.meeting;
      return {
        ...state,
        pending: false,
        actualMeeting: meeting,
      };
    case MEETING_SET_RESPONSE.PENDING:
      return { ...state, error: null, pending: true };
    case MEETING_SET_RESPONSE.ERROR:
      return { ...state, pending: false, error: action.info };
    case RESET_CURRENT_MEETING:
      return { ...state, actualMeeting: null };
    default:
      return state;
  }
}
export const IsError = (state) => state.meeting.error;
export const IsPending = (state) => state.meeting.pending;
export const GetState = (state) =>
  state.meeting.pending
    ? CONST.reducerStates.pending
    : state.meeting.error
    ? CONST.reducerStates.error
    : CONST.reducerStates.success;
export const GetAvailable = (state) => state.meeting.availableMeetings;
export const GetInWorkspace = (state) => state.meeting.workspaceMeetings;
export const GetLastEndedMeeting = (state) => state.meeting.lastEndedCall;

export const GetActual = (state) => state.meeting.actualMeeting;
export const GetMeetingUsers = (state) => state.meeting.meetingUsers;

export const GetUsers = (state) => (val) => {
  return !state.meeting.users ? null : state.meeting.users[val];
};

export const GetUserNames = (state) => state.meeting.userNames;

export const GetHostUser = (state) => state.meeting.isHost;
export const SetLobby = (state) => state.meeting.set_lobby;
