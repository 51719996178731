import {
  AUTH_PENDING,
  AUTH_ERROR,
  AUTH_SUCCESS,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_PENDING,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  PASS_RESET_REQUEST_SUCCESS,
  PASS_RESET_REQUEST_ERROR,
  PASS_RESET_REQUEST_PENDING,
  PASS_RESET_SUCCESS,
  PASS_RESET_ERROR,
  PASS_RESET_PENDING,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_PENDING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_PENDING,
  USER_INVITE_ERROR,
  USER_INVITE_SUCCESS,
  USER_INVITE_PENDING,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_ERROR,
  USER_GET_ME_PENDING,
  USER_GET_ME_SUCCESS,
  USER_GET_ME_ERROR,
  UPDATE_USER_INSTRUCTIONS,
} from "./../actions/login";

import { initialState } from "initialState.js";
import { CONST } from "../../Constants";

import { User } from "_redux/_models/User";

export function loginReducer(state = initialState, action) {
  //invitedUser
  switch (action.type) {
    case CONFIRM_EMAIL_SUCCESS: {
      let user = state.user;
      user.email_confirmed = true;
      return {
        ...state,
        user: { ...user },
      };
    }
    case CONFIRM_EMAIL_ERROR: {
      //  console.log(action)
      return state;
    }
    case USER_INVITE_PENDING:
      return { ...state, error: null, pending: true };
    case USER_INVITE_SUCCESS:
      return {
        ...state,
        pending: false,
        invitedUser: action.payload.user ? action.payload.user : {},
      };
    case USER_INVITE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.info,
        inviteUserError: action.info,
      };

    case LOGOUT_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        pending: false,
        token: "",
        loggedOut: true,
        user: null,
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.info,
      };
    case AUTH_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
        authpending: true,
        pendingLocation: action.payload.pendingLocation,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        pending: false,
        authpending: false,
        token: action.payload.token,
        user: User.Parse(action.payload.user),
      };
    case AUTH_ERROR:
      return {
        ...state,
        pending: false,
        authpending: false,
        error: action.info,

        registerinfo: null,
        registererror: null,
        registerpending: false,
      };
    case LOGIN_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        token: action.payload.token,
        user: User.Parse(action.payload.user),
      };
    case LOGIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.info,
      };
    case USER_GET_ME_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case USER_GET_ME_SUCCESS:
      return {
        ...state,
        pending: false,
        user: User.Parse(action.payload.user),
      };
    case USER_GET_ME_ERROR:
      return {
        ...state,
        pending: false,
        error: action.info,
      };

    case USER_UPDATE_PENDING:
      //console.log(action);
      return {
        ...state,
        error: null,
        pending: true,
      };
    case USER_UPDATE_SUCCESS:
      //console.log(action);
      return {
        ...state,
        pending: false,
        //token: action.payload.token,
        user: User.Parse(action.payload.user),
      };
    case USER_UPDATE_ERROR:
      console.log(action);
      return {
        ...state,
        pending: false,
        error: action.info,
      };
    case PASS_RESET_REQUEST_SUCCESS:
      return {
        ...state,
        passresetrequestpending: false,
        passresetrequestinfo: action.info,
      };
    case PASS_RESET_REQUEST_ERROR:
      return {
        ...state,
        passresetrequestpending: false,
        passresetrequesterror: action.info,
      };
    case PASS_RESET_REQUEST_PENDING:
      return {
        ...state,
        passresetrequesterror: null,
        passresetrequestpending: true,
      };
    case PASS_RESET_SUCCESS:
      return {
        ...state,
        passresetpending: false,
        passresetinfo: action.info,
      };
    case PASS_RESET_ERROR:
      return {
        ...state,
        passresetpending: false,
        passreseterror: action.info,
      };
    case PASS_RESET_PENDING:
      return {
        ...state,
        passreseterror: null,
        passresetpending: true,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        registerpending: false,
        registerinfo: action.info,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        registerpending: false,
        registererror:
          action.payload && action.payload.id ? action.payload.id : action.info,
      };
    case REGISTER_PENDING:
      return {
        ...state,
        registererror: null,
        registerpending: true,
      };
    /*case SET_USER_TYPE:
			localStorage.setItem("user_data", JSON.stringify(action.data));
			return{
				...state,
				user: action.data,
			}*/
    case UPDATE_USER_INSTRUCTIONS.SUCCESS:
      return {
        ...state,
        pending: false,
        user: User.Parse(action.payload.user),
      };
    default:
      return state;
  }
}

export const getPendingLocation = (state) => state.login.pendingLocation;
export const getLogin = (state) => state.login.token;
export const getLogout = (state) => state.login.loggedOut;

export const getUser = (state) => state.login.user;
export const IsUserLoggedIn = (state) => {
  if (
    state.login.user != null &&
    state.login.user.email != null &&
    state.login.user.email !== CONST._empty
  )
    return true;
  return false;
};

//export const getAuth = (state) => state.login.token;
//export const getAuthPending = (state) => state.login.authpending;
export const getLoginPending = (state) => state.login.pending;
export const getLoginError = (state) => state.login.error;
export const getPassResetRequestDone = (state) =>
  state.login.passresetrequestinfo;
export const getPassResetRequestError = (state) =>
  state.login.passresetrequesterror;
export const getPassResetRequestPending = (state) =>
  state.login.passresetrequestpending;

export const getPassResetDone = (state) => state.login.passresetinfo;
export const getPassResetError = (state) => state.login.passreseterror;
export const getPassResetPending = (state) => state.login.passresetpending;

export const getRegisterDone = (state) => state.login.registerinfo;
export const getRegisterError = (state) => state.login.registererror;
export const getRegisterPending = (state) => state.login.registerpending;

export const LoadInvitedUser = (state) => state.login.invitedUser;
export const getInvitedUserError = (state) => state.login.inviteUserError;
