import { GenericAction } from "./../actions/api.js";

export const MESSAGE_GET_PENDING = "MESSAGE_GET_PENDING";
export const MESSAGE_GET_SUCCESS = "MESSAGE_GET_SUCCESS";
export const MESSAGE_GET_ERROR = "MESSAGE_GET_ERROR";

export const MESSAGE_CREATE_PENDING = "MESSAGE_CREATE_PENDING";
export const MESSAGE_CREATE_SUCCESS = "MESSAGE_CREATE_SUCCESS";
export const MESSAGE_CREATE_ERROR = "MESSAGE_CREATE_ERROR";

export const MESSAGE_SETSHOWN_PENDING = "MESSAGE_SETSHOWN_PENDING";
export const MESSAGE_SETSHOWN_SUCCESS = "MESSAGE_SETSHOWN_SUCCESS";
export const MESSAGE_SETSHOWN_ERROR = "MESSAGE_SETSHOWN_ERROR";

export const MESSAGE_GET_AVAILABLE_PENDING = "MESSAGE_GET_AVAILABLE_PENDING";
export const MESSAGE_GET_AVAILABLE_SUCCESS = "MESSAGE_GET_AVAILABLE_SUCCESS";
export const MESSAGE_GET_AVAILABLE_ERROR = "MESSAGE_GET_AVAILABLE_ERROR";

export const MESSAGE_GET_NEW_PENDING = "MESSAGE_GET_NEW_PENDING";
export const MESSAGE_GET_NEW_SUCCESS = "MESSAGE_GET_NEW_SUCCESS";
export const MESSAGE_GET_NEW_ERROR = "MESSAGE_GET_NEW_ERROR";

export const MESSAGE_DELETE_PENDING = "MESSAGE_DELETE_PENDING";
export const MESSAGE_DELETE_SUCCESS = "MESSAGE_DELETE_SUCCESS";
export const MESSAGE_DELETE_ERROR = "MESSAGE_DELETE_ERROR";

export const MESSAGE_SEND_FILE_PENDING = "MESSAGE_SEND_FILE_PENDING";
export const MESSAGE_SEND_FILE_SUCCESS = "MESSAGE_SEND_FILE_SUCCESS";
export const MESSAGE_SEND_FILE_ERROR = "MESSAGE_SEND_FILE_ERROR";

export const GET_GROUP_PENDING = "GET_GROUP_PENDING";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_ERROR = "GET_GROUP_ERROR";

export const CREATE_GROUP_PENDING = "CREATE_GROUP_PENDING";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

export const SEND_GROUP_MESSAGE_PENDING = "SEND_GROUP_MESSAGE_PENDING";
export const SEND_GROUP_MESSAGE_SUCCESS = "SEND_GROUP_MESSAGE_SUCCESS";
export const SEND_GROUP_MESSAGE_ERROR = "SEND_GROUP_MESSAGE_ERROR";

export const GET_GROUP_MESSAGES_PENDING = "GET_GROUP_MESSAGES_PENDING";
export const GET_GROUP_MESSAGES_SUCCESS = "GET_GROUP_MESSAGES_SUCCESS";
export const GET_GROUP_MESSAGES_ERROR = "GET_GROUP_MESSAGES_ERROR";

export const GET_USER_GROUPS_PENDING = "GET_USER_GROUPS_PENDING";
export const GET_USER_GROUPS_SUCCESS = "GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_ERROR = "GET_USER_GROUPS_ERROR";

export const ADD_GROUP_USERS_PENDING = "ADD_GROUP_USERS_PENDING";
export const ADD_GROUP_USERS_SUCCESS = "ADD_GROUP_USERS_SUCCESS";
export const ADD_GROUP_USERS_ERROR = "ADD_GROUP_USERS_ERROR";

export const REMOVE_GROUP_USERS_PENDING = "REMOVE_GROUP_USERS_PENDING";
export const REMOVE_GROUP_USERS_SUCCESS = "REMOVE_GROUP_USERS_SUCCESS";
export const REMOVE_GROUP_USERS_ERROR = "REMOVE_GROUP_USERS_ERROR";

export const GROUP_REMOVE_MYSELF_PENDING = "GROUP_REMOVE_MYSELF_PENDING";
export const GROUP_REMOVE_MYSELF_SUCCESS = "GROUP_REMOVE_MYSELF_SUCCESS";
export const GROUP_REMOVE_MYSELF_ERROR = "GROUP_REMOVE_MYSELF_ERROR";

export const DELETE_GROUP_PENDING = "DELETE_GROUP_PENDING";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";

export const UPDATE_GROUP_PENDING = "UPDATE_GROUP_PENDING";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";

export const MESSAGE_ADD_FROM_WS = 'MESSAGE_ADD_FROM_WS';
export const MESSAGE_ADD_NEW = 'MESSAGE_ADD_NEW';

export const CLEAR_GROUP_INFO = "CLEAR_GROUP_INFO";

export function _get(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    MESSAGE_GET_PENDING,
    MESSAGE_GET_SUCCESS,
    MESSAGE_GET_ERROR
  );
}
export function _getAvailable(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    MESSAGE_GET_AVAILABLE_PENDING,
    MESSAGE_GET_AVAILABLE_SUCCESS,
    MESSAGE_GET_AVAILABLE_ERROR
  );
}
export function _create(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    MESSAGE_CREATE_PENDING,
    MESSAGE_CREATE_SUCCESS,
    MESSAGE_CREATE_ERROR
  );
}


export function _addMessageFromWs(data, isGroup) {

  return {
    type: MESSAGE_ADD_FROM_WS,
    data: data,
    isGroup:isGroup
  };
}
export function _addMessageNew(data) {
  return {
    type: MESSAGE_ADD_NEW,
    data: data,
  };
}



export function _getNew(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    MESSAGE_GET_NEW_PENDING,
    MESSAGE_GET_NEW_SUCCESS,
    MESSAGE_GET_NEW_ERROR
  );
}
export function _delete(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    MESSAGE_DELETE_PENDING,
    MESSAGE_DELETE_SUCCESS,
    MESSAGE_DELETE_ERROR
  );
}
export function _setAsShown(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    MESSAGE_SETSHOWN_PENDING,
    MESSAGE_SETSHOWN_SUCCESS,
    MESSAGE_SETSHOWN_ERROR
  );
}

export function _sendFile(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    MESSAGE_SEND_FILE_PENDING,
    MESSAGE_SEND_FILE_SUCCESS,
    MESSAGE_SEND_FILE_ERROR
  );
}

export function _getGroup(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    GET_GROUP_PENDING,
    GET_GROUP_SUCCESS,
    GET_GROUP_ERROR
  );
}

export function _updateGroupTitle(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    UPDATE_GROUP_PENDING,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_ERROR
  );
}

export function _sendGroupMessage(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    SEND_GROUP_MESSAGE_PENDING,
    SEND_GROUP_MESSAGE_SUCCESS,
    SEND_GROUP_MESSAGE_ERROR
  );
}

export function _getGroupMessages(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    GET_GROUP_MESSAGES_PENDING,
    GET_GROUP_MESSAGES_SUCCESS,
    GET_GROUP_MESSAGES_ERROR
  );
}

export function _createGroupMessage(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    CREATE_GROUP_PENDING,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_ERROR
  );
}

export function _getUserGroups(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    GET_USER_GROUPS_PENDING,
    GET_USER_GROUPS_SUCCESS,
    GET_USER_GROUPS_ERROR
  );
}

export function _groupAddUsers(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    ADD_GROUP_USERS_PENDING,
    ADD_GROUP_USERS_SUCCESS,
    ADD_GROUP_USERS_ERROR
  );
}

export function _groupRemoveUsers(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    REMOVE_GROUP_USERS_PENDING,
    REMOVE_GROUP_USERS_SUCCESS,
    REMOVE_GROUP_USERS_ERROR
  );
}

export function _groupRemoveMyself(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    GROUP_REMOVE_MYSELF_PENDING,
    GROUP_REMOVE_MYSELF_SUCCESS,
    GROUP_REMOVE_MYSELF_ERROR
  );
}

export function _deleteGroup(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    DELETE_GROUP_PENDING,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_ERROR
  );
}

export function _clearGroupInfo() {
  return {
    type: CLEAR_GROUP_INFO,
  };
}
