import { CONST } from "../../Constants";
import { DynamicSort } from "KlarHelper";

export class MessagesDB {
  constructor() {
    this.messages = new Map();
    //this.callMessage = {}; //TODO CALL OBECJT
    this.actualCall = {meeting_uuid: CONST._empty, action: CONST._empty};
  }

  SetMessages(messages, email) {
    messages.map((msg) => this.AddMessage(msg, email));
  }

  GetHash() {
    return this.hash;
  }

  ResetMessage() {
    this.messages = new Map();
  }

  AddMessage(msg, id) {
    if (id == null) {
      id = "email";
      console.log("NO ID SET");
    }

    if (this.messages.get(id) == null)
      this.messages.set(id, createEmptyMessagesCollection());

    let entry = this.messages.get(id);

    let parsedMessage;
    if (msg.message_type && msg.message_type === CONST.messagetypes.group_msg) {
      parsedMessage = ParseGroupMessage(msg);
      if (!entry.isGroup) entry.isGroup = true;
    } else {
      parsedMessage = ParseMessage(msg);
    }

    let list = entry.list;
    if (
      parsedMessage.type === CONST.messagetypes.text ||
      parsedMessage.type === CONST.messagetypes.group_msg
    ) {
      list = entry.list;
    } else if (
      parsedMessage.type === CONST.messagetypes.system ||
      parsedMessage.type === CONST.messagetypes.call
    ) {
      entry = this.messages.get(id);
      list = entry.system;
    }

    let found = -1;
    for (let i = 0; i < list.length; i++) {
      if (msg.id != null && list[i].id != null && list[i].id === msg.id) {
        found = i;
        break;
      } else if (
        list[i].created_at === parsedMessage.created_at &&
        list[i].from === parsedMessage.from
      ) {
        found = i;
        break;
      } else if (list[i]?.internal && list[i].msg === parsedMessage.msg) {
        found = i;
        break;
      }
    }
    // console.log(id, found, parsedMessage, list);
    if (found < 0) list.push(parsedMessage);
    else list[found] = parsedMessage;

    entry.hash = new Date().getTime() + "" + list.length;
    if (parsedMessage.type === CONST.messagetypes.call) {
      let msgObject;
      if (
        typeof parsedMessage.msg === "string" ||
        parsedMessage.msg instanceof String
      )
        msgObject = JSON.parse(parsedMessage.msg);
      else msgObject = parsedMessage.msg;

      if (msgObject.to == null) msgObject.to = [];
      parsedMessage.msg = msgObject;

      /*if (
        this.callMessage.lastMessage == null ||
        this.callMessage.lastMessage.msg.uuid !== msgObject.uuid ||
        this.callMessage.lastMessage.msg.action !== msgObject.action
      ) {
        this.callMessage.lastMessage = parsedMessage;

        if (parsedMessage.msg.action === CONST.callstates.hangup)
          this.callMessage.lastMessage = null;
      }*/
    }

    if (msg.type && msg.type === CONST.messagetypes.group_msg) {
      list.sort(DynamicSort("created_at"));
    } else {
      list.sort(DynamicSort("id"));
    }

    this.recalculateNewMessages(id);
    this.hash = new Date().getTime();
  }

  recalculateNewMessages(id) {
    const entry = this.messages.get(id);
    if (entry == null) return;

    let _new = 0;

    const lastMessageInGroupReadTime = localStorage.getItem(
      "lastOldMessageInGroup_" + id
    )
      ? parseInt(localStorage.getItem("lastOldMessageInGroup_" + id))
      : 0;

    entry.list.map((msg) => {
      if (
        msg.isGroupMessage &&
        msg.is_new &&
        msg.timestamp.getTime() > lastMessageInGroupReadTime
      ) {
        _new++;
      } else if (msg.is_new && msg.from === id) _new++;
    });
    entry.new = _new;
  }

  GetCall_depr() {
    console.log("ERROR", "GetCall in MessageDB");
    return this.callMessage;
  }

  GetMessagesList(id) {
    // console.log("GetMessagesList", this.messages.get(id));
    if (this.messages.get(id)) return this.messages.get(id);
    return createEmptyMessagesCollection();
  }

  GetNewMessages(email = null) {
    let _new = 0;

    this.messages.forEach((value, key) => {
      if (!email || key === email) {
        _new += value.new;
      }
    });
    // console.log("NEW", _new);
    return _new;
  }
}

export function ParseMessage(json) {
  return {
    id: json.id,
    from: json.from,
    to: json.to,
    created_at: json.created_at,
    timestamp: json.created_at ? new Date(json.created_at) : new Date(),
    is_new: json.is_new === 1 || json.is_new === true,
    is_deleted: json.is_deleted === 1 || json.is_deleted === true,
    msg: json.msg,
    type:
      json.type === "call" ? CONST.messagetypes.call : CONST.messagetypes.text,
  };
}

export function ParseGroupMessage(json) {
  return {
    isGroupMessage: true,
    // id: json.id,
    from: json.from,
    from_name: json.user?.firstname + " " + json.user?.lastname,
    to: json.message_group_id ? json.message_group_id : json.to,
    created_at: json.created_at,
    timestamp: json.created_at ? new Date(json.created_at) : new Date(),
    is_new: json.is_new === 1 || json.is_new === true,
    // is_deleted: json.is_deleted === 1 || json.is_deleted === true,
    msg: json.msg,
    type: json.message_type ? json.message_type : json.type,
  };
}

const createEmptyMessagesCollection = function () {
  return { list: [], system: [], new: 0, hash: CONST._empty, isGroup: false };
};
