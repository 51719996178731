import {RESPONSE_TYPE} from './../api/_api.js';

export const WORKSPACE_GET = {
  PENDING : 'WORKSPACE_GET_PENDING',
  SUCCESS : 'WORKSPACE_GET_SUCCESS',
  ERROR : 'WORKSPACE_GET_ERROR'
}
export const WORKSPACE_CREATE = {
  PENDING : 'WORKSPACE_CREATE_PENDING',
  SUCCESS : 'WORKSPACE_CREATE_SUCCESS',
  ERROR : 'WORKSPACE_CREATE_ERROR'
}
export const WORKSPACE_UPDATE = {
  PENDING : 'WORKSPACE_UPDATE_PENDING',
  SUCCESS : 'WORKSPACE_UPDATE_SUCCESS',
  ERROR : 'WORKSPACE_UPDATE_ERROR'
};
export const WORKSPACE_GET_AVAILABLE = {
  PENDING : 'WORKSPACE_GET_AVAILABLE_PENDING',
  SUCCESS : 'WORKSPACE_GET_AVAILABLE_SUCCESS',
  ERROR : 'WORKSPACE_GET_AVAILABLE_ERROR'
};
export const WORKSPACE_DELETE = {
  PENDING : 'WORKSPACE_DELETE_PENDING',
  SUCCESS : 'WORKSPACE_DELETE_SUCCESS',
  ERROR : 'WORKSPACE_DELETE_ERROR'
};
export const WORKSPACE_GET_AVAILABLE_IN_COMPANIES=
{
  PENDING : 'WORKSPACE_GET_AVAILABLE_IN_COMPANIES_PENDING',
  SUCCESS : 'WORKSPACE_GET_AVAILABLE_IN_COMPANIES_SUCCESS',
  ERROR : 'WORKSPACE_GET_AVAILABLE_IN_COMPANIES_ERROR'
};

export const WORKSPACEUSERS_GET_SUCCESS = 'WORKSPACEUSERS_GET_SUCCESS';
export const WORKSPACEUSERS_UDPATE_SUCCESS = 'WORKSPACEUSERS_UDPATE_SUCCESS';
export const WORKSPACEUSERS_ADD_SUCCESS = 'WORKSPACEUSERS_ADD_SUCCESS';
export const WORKSPACEUSERS_DELETE_SUCCESS = 'WORKSPACEUSERS_DELETE_SUCCESS';

export function _getUsers(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
		return {type: type, payload:info};
	else if(type === RESPONSE_TYPE.SUCCESS)
	  return {type: WORKSPACEUSERS_GET_SUCCESS,info: info,payload: payload};	
	else if(type === RESPONSE_TYPE.ERROR)
		return {type: type,info: info,payload: payload};
}
export function _updateUser(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
		return {type: type, payload:info};
	else if(type === RESPONSE_TYPE.SUCCESS)
	  return {type: WORKSPACEUSERS_UDPATE_SUCCESS,info: info,payload: payload};	
	else if(type === RESPONSE_TYPE.ERROR)
		return {type: type,info: info,payload: payload};
}
export function _addUsers(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
		return {type: type, payload:info};
	else if(type === RESPONSE_TYPE.SUCCESS)
	  return {type: WORKSPACEUSERS_ADD_SUCCESS,info: info,payload: payload};	
	else if(type === RESPONSE_TYPE.ERROR)
		return {type: type,info: info,payload: payload};
}
export function _deleteUsers(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
		return {type: type, payload:info};
	else if(type === RESPONSE_TYPE.SUCCESS)
	  return {type: WORKSPACEUSERS_DELETE_SUCCESS,info: info,payload: payload};	
	else if(type === RESPONSE_TYPE.ERROR)
		return {type: type,info: info,payload: payload};
}

export function _getAvailableInCompanies(type, info, payload) {  
  return {type: WORKSPACE_GET_AVAILABLE_IN_COMPANIES[type],info: info,payload: payload};	
}

export function _getAvailable(type, info, payload) {  
  return {type: WORKSPACE_GET_AVAILABLE[type],info: info,payload: payload};	
}
export function _get(type, info, payload) {  
  return {type: WORKSPACE_GET[type],info: info,payload: payload};	
}
export function _delete(type, info, payload) {  
  return {type: WORKSPACE_DELETE[type],info: info,payload: payload};	
}
export function _update(type, info, payload) {  
  return {type: WORKSPACE_UPDATE[type],info: info,payload: payload};	
}
export function _create(type, info, payload) {  
  return {type: WORKSPACE_CREATE[type],info: info,payload: payload};	
}