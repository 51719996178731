import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: "de",
    fallbackLng: "de",
    debug: process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_I18N_SILENT === "false",
    ns: ["translation"],
    defaultNS: "translation",
    // backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: true,
    },
  });
export default i18n;
