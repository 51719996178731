import {
  SOCIAL_MESSAGE_CREATE,
  SOCIAL_MESSAGE_GET_IN_PINBOAD,
  SOCIAL_MESSAGE_DELETE,
  SOCIAL_MESSAGE_UPDATE,
  SOCIAL_LIKE_CREATE,
  SOCIAL_MESSAGE_COMMENTS_GET,
  SOCIAL_ACTUAL_SHARING,
  SOCIAL_MESSAGE_SET_TO_PINBOAD,
  SOCIAL_MESSAGE_USER,
  SOCIAL_LIKE_SET,
  SOCIAL_LIKE_GET,
  SOCIAL_MESSAGE_GET,
  SOCIAL_MESSAGE_VIEW_ADD,
} from "./../actions/social";
import { initialState } from "initialState.js";
import { CONST } from "../../Constants";
import { APP_LOGOUT_USER } from "../actions/app";
import { ParseSocialMessage } from "../_models/SocialMessage";

export function getMessageStoreByTableName(tableName) {
  if (tableName === "company" || tableName === "companies")
    return "companyMessages";
  else if (tableName === "workspace" || tableName === "workspaces")
    return "workspaceMessages";

  console.error("unknown tableName", tableName);
  return null;
}
export function socialReducer(state = initialState, action) {
  switch (action.type) {
    case SOCIAL_ACTUAL_SHARING:
      return {
        ...state,
        actualSharing: {
          entity: action.entity,
          options: action.options,
          ts: new Date().getTime(),
        },
      };
    case APP_LOGOUT_USER:
      return { ...state, messages: {} };

    /* #region SOCIAL_MESSAGE_CREATE */
    case SOCIAL_MESSAGE_GET.SUCCESS:{
   
      let newState = {
        ...state,
        error: null,
        pending: false,
        shouldUpdate: new Date().getTime(),
      };
      //console.log(action.payload)
      let msg = ParseSocialMessage(action.payload.msg);

      if (msg.isComment()) {
        if (newState.actualComments.msgId === msg.entity_id) {
         // console.log("search and replace comment")
          //newState.actualComments.comments.unshift(msg);
        } else {
          newState.actualComments = { msgId: msg.entity_id, comments: [msg] };
        }


      } else {

        for(let i in newState.messages){
          if(newState.messages[i].uuid === msg.uuid){
          //  console.log("search and replace msg", i, msg)
          }
        }
        //newState.messages.push(msg);
        newState.messages.sort((a, b) =>
          a.created_at > b.created_at ? -1 : 1
        );

      }

      //newState.messages.
      return newState;
    }
    case SOCIAL_MESSAGE_CREATE.SUCCESS: {

      let newState = {
        ...state,
        error: null,
        pending: false,
        shouldUpdate: new Date().getTime(),
      };

      let msg = ParseSocialMessage(action.payload.msg);
      
      if (msg.isComment()) {
        if (newState.actualComments.msgId === msg.entity_id) {
          newState.actualComments.comments.unshift(msg);
        } else {
          newState.actualComments = { msgId: msg.entity_id, comments: [msg] };
        }

        for (let i in newState.messages) {
          if (newState.messages[i].uuid === msg.entity_id)
            newState.messages[i].cum_messages++;
        }

        //TODO should be checked
        newState.createdMessage = msg;
      } else {
        newState.messages.push(msg);
        newState.messages.sort((a, b) =>
          a.created_at > b.created_at ? -1 : 1
        );
        newState.createdMessage = msg;
      }
      return newState;
    }
    case SOCIAL_MESSAGE_CREATE.PENDING:
      return { ...state, error: null, pending: true };
    case SOCIAL_MESSAGE_CREATE.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_MESSAGE_CREATE */

    /* #region SOCIAL_MESSAGE_COMMENTS_GET */

    case SOCIAL_MESSAGE_COMMENTS_GET.SUCCESS: {
      let newState = { ...state, error: null, pending: false };

      const msgId = action.payload?.msg_id;
      if (msgId) {
        newState.actualComments = {
          msgId: msgId,
          comments: action.payload.comments,
        };
        return { ...newState };
       
      }
      return state;
    }
    case SOCIAL_MESSAGE_COMMENTS_GET.PENDING: {
      const msg = state.messages.find(
        (msg) => msg.uuid === action.payload?.uuid
      );
      if (msg)
        return {
          ...state,
          error: null,
          pending: true,
          actualComments: {
            msgId: action.payload?.uuid,
            comments: new Array(msg.comments).fill(CONST.skeleton_loading),
          },
        };

      if (state.actualComments.msgId === action.payload?.uuid)
        return { ...state, error: null, pending: true };
      else
        return {
          ...state,
          error: null,
          pending: true,
          actualComments: {
            msgId: action.payload?.uuid,
            comments:
              state.actualComments.msgId === action.payload?.uuid
                ? state.actualComments.comments
                : new Array(5).fill(CONST.skeleton_loading),
          },
        };
    }
    case SOCIAL_MESSAGE_COMMENTS_GET.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_MESSAGE_COMMENTS_GET */

    /*#region SOCIAL_MESSAGE_DELETE */
    case SOCIAL_MESSAGE_DELETE.SUCCESS: {
     
      let newState = {
        ...state,
        error: null,
        pending: false,
        createdMessage: null,
        actualComments: {
          msgId: null,
          comments: null,
        },
        shouldUpdate: new Date().getTime(),
      };

      let msg = ParseSocialMessage(action.payload.msg);
      let filtered = newState.messages.filter(function (_msg) {
        return msg.uuid != _msg.uuid;
      });
      newState.messages = filtered;

      filtered = newState.userMessages.filter(function (_msg) {
        return msg.uuid != _msg.uuid;
      });
      newState.userMessages = filtered;
      return newState;
    }
    case SOCIAL_MESSAGE_DELETE.PENDING:
      return { ...state, error: null, pending: true };
    case SOCIAL_MESSAGE_DELETE.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_MESSAGE_DELETE */

    /* #region SOCIAL_MESSAGE_UPDATE */
      case SOCIAL_MESSAGE_UPDATE.SUCCESS: {
        let newState = {
          ...state,
          error: null,
          pending: false,
          shouldUpdate: new Date().getTime(),
        };
        let msg = ParseSocialMessage(action.payload.msg);
        for (let i in newState.messages) {
          if (newState.messages[i].uuid === msg.uuid) {
            newState.messages[i] = msg;
          }
        }

      
        return newState;
      }
      case SOCIAL_MESSAGE_UPDATE.PENDING:
        return { ...state, error: null, pending: true };
      case SOCIAL_MESSAGE_UPDATE.ERROR:
        return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_MESSAGE_UPDATE */

    /* #region SOCIAL_MESSAGE_GET_IN_PINBOAD */
    case SOCIAL_MESSAGE_GET_IN_PINBOAD.SUCCESS:
      if (action.payload.entity_id) {
        const messages = [];
        action.payload.msgs.map((msg) => {
          const smsg = ParseSocialMessage(msg);
          if (smsg) messages.push(smsg);
        });
        return {
          ...state,
          entity_id: action.payload.entity_id,
          messages: messages,
          createdMessage: null,
          error: null,
          pending: false,
        };
      }
      return { ...state, error: null, pending: false, messages: [] };
    case SOCIAL_MESSAGE_GET_IN_PINBOAD.PENDING:
      return { ...state, error: null, pending: true };
    case SOCIAL_MESSAGE_GET_IN_PINBOAD.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_MESSAGE_GET_IN_PINBOAD */

    /* #region SOCIAL_MESSAGE_GET_IN_PINBOAD */
    case SOCIAL_MESSAGE_SET_TO_PINBOAD.SUCCESS:
      return { ...state, error: null, pending: false };
    case SOCIAL_MESSAGE_SET_TO_PINBOAD.PENDING:
      return { ...state, error: null, pending: true };
    case SOCIAL_MESSAGE_SET_TO_PINBOAD.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_MESSAGE_GET_IN_PINBOAD */

    /* #region SOCIAL_LIKE_CREATE */
    case SOCIAL_LIKE_CREATE.SUCCESS:
      let newState = {
        ...state,
        error: null,
        pending: false,
        shouldUpdate: new Date().getTime(),
      };
      if (action.payload.added === 0)
        //already liked
        return newState;
      for (let i in newState.messages) {
        if (newState.messages[i].uuid === action.payload.like.entity_id) {
          newState.messages[i].cum_likes++;
          newState.messages[i] = { ...newState.messages[i] };
        }
      }
      return newState;
    case SOCIAL_LIKE_CREATE.PENDING:
      return state;
    case SOCIAL_LIKE_CREATE.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_MESSAGE_GET_IN_PINBOAD */

    /* #region SOCIAL_LIKE_SET */
    case SOCIAL_LIKE_SET.SUCCESS:
      let likeState = {
        ...state,
        error: null,
        pending: false,
        shouldUpdate: new Date().getTime(),
      };
      if (action.payload.added === 0)
        //already liked
        return likeState;
      for (let i in likeState.messages) {
        if (likeState.messages[i].uuid === action.payload.like.entity_id) {
          likeState.messages[i].cum_likes++;
          likeState.messages[i] = { ...likeState.messages[i] };
        }
      }
      return likeState;
    case SOCIAL_LIKE_SET.PENDING:
      return state;
    case SOCIAL_LIKE_SET.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_LIKE_SET */

    /* #region SOCIAL_LIKE_SET */
    case SOCIAL_LIKE_GET.SUCCESS:
      return {
        ...state,
        likes: action.payload.likes,
        error: null,
        pending: false,
      };
    case SOCIAL_LIKE_GET.PENDING:
      return state;
    case SOCIAL_LIKE_GET.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_LIKE_SET */

    /* #region SOCIAL_MESSAGE_USER */
    case SOCIAL_MESSAGE_USER.SUCCESS:
      const messages = state.userMessages;
      let updated = 0;

      action.payload.msgs.map((msg) => {
        const smsg = ParseSocialMessage(msg);
        if (smsg && !messages.find((msg)=>msg.uuid===smsg.uuid)){
          updated++;          
          messages.push(smsg);
        }
      });
      messages.sort((a, b) =>
        a.created_at > b.created_at ? -1 : 1
      );
      let newMessages = 0;
      messages.map((a)=>newMessages+=a.isNew);      
      return { ...state, error: null, pending: false, userMessages: messages, newMessages:newMessages, shouldUpdate:updated>0?new Date().getTime():state.shouldUpdate};    
    case SOCIAL_MESSAGE_USER.PENDING:
      return state;
    case SOCIAL_MESSAGE_USER.ERROR:
      return { ...state, pending: false, error: action.info };
    /* #endregion SOCIAL_MESSAGE_USER */
    case SOCIAL_MESSAGE_VIEW_ADD:
    {
      const messages = state.userMessages;
      let updated = 0;
      messages.map((msg) => {
        if (msg.uuid === action.val){
          updated++;          
          msg.isNew = false;
         
        }
      });
      let newMessages = 0;
      messages.map((a)=>newMessages+=a.isNew);
      return { ...state, error: null, pending: false, userMessages: messages, newMessages:newMessages, shouldUpdate:updated>0?new Date().getTime():state.shouldUpdate};   
    }
    default:
      return state;
  }
}

export const IsError = (state) => state.social.error;
export const IsPending = (state) => state.social.pending;
export const GetState = (state) =>
  state.social.pending
    ? CONST.reducerStates.pending
    : state.social.error
    ? CONST.reducerStates.error
    : CONST.reducerStates.success;

export const CreatedMessage = (state) => {
  return state.social.createdMessage;
};
export const ShouldSocialUpdate = (state) => state.social.shouldUpdate;
export const GetPinboardMessages = (state, storeKey) => {
  return state.social[storeKey];
};
export const getLikes = (state) => state.social.likes;
export const NewMessages = (state) => state.social.newMessages;