import { CONST } from "Constants";
import i18next from "i18next";
import { SetLocalItem } from "LocalStorage";
import { DateTime } from "luxon";
import packageJson from "../package.json";
import { _ } from "./Constants";
export function DynamicSort(property) {
  //array.sort(DynamicSort('name'))
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function GetDomain() {
  let parts = getParts();

  if (parts.length >= 1) return parts[0];
  return packageJson.settings.default_company;  
}

export function GetWorkspace() {
  let parts = getParts();
  if (parts.length >= 2) return parts[1];

  return CONST._empty;
}

export function CreateUrl(path, id) {
  if (path === CONST.pathes.company) return CONST._slash + id + CONST._slash;
  else if (path === CONST.pathes.workspace) {
    if (id == null) return CONST._slash + GetDomain() + CONST._slash;
    return CONST._slash + GetDomain() + CONST._slash + id + CONST._slash;
  }
  if (path.indexOf("*") !== -1) return path.split("*").join(id);

  return path + id;
}

export function GetIdFromURL(path, id) {
  if (path === CONST.pathes.company) return GetDomain();
  else if (path === CONST.pathes.workspace) return GetWorkspace();

  let t = document.location.hash.split(path.split("*")[0]);
  if (t.length > 1) {
    if (t[1].split("/").length > id) return t[1].split("/")[id];
    else return t[1].split("/")[0];
  }
}

export function GetURLPart(id) {
  let parts = GetURLParts();
  if (parts.length > id && id >= 0) return parts[id];
  return CONST._empty;
}

export function GetURLParts() {
  return document.location.hash.split(CONST._slash);
}

export function SetItemsViewType(type) {
  let app = document.getElementsByClassName("App")[0];
  if (type === CONST.classes.view_tiles) {
    SetLocalItem(CONST._cookies.item_view_type, CONST.classes.view_tiles);
    app.classList.add(CONST.classes.view_tiles);
    app.classList.remove(CONST.classes.view_list);
  } else {
    SetLocalItem(CONST._cookies.item_view_type, CONST.classes.view_list);
    app.classList.remove(CONST.classes.view_tiles);
    app.classList.add(CONST.classes.view_list);
  }
}

export function RenderDateTime(date) {
  let _time =
    getTwoDigits(date.getHours()) + ":" + getTwoDigits(date.getMinutes());
  let _date =
    getTwoDigits(date.getDate()) + "." + getTwoDigits(date.getMonth() + 1);
  return { date: _date, time: _time };
}

export function AddMinutesToDateObject(dateObject, minutes) {
  let d = new Date(dateObject.getTime() + minutes * 60000);
  return d;
}

export function GetLocalDateTime(datetime) {
  return ParseDateTime(datetime).toISO();
}

export function ParseDateTime(datetime) {
  let ts = DateTime.fromSQL(datetime, { zone: "Europe/Prague" });
  if(ts.invalid != null)
    ts = ParseDateTimefromIso(datetime);

  return ts;
}
export function ParseDateTimefromIso(date) {
  return DateTime.fromISO(date);
}


const spans={
  'years':'year',
  'months':'month',
  'weeks':'week',
  'days':'day',
  'hours':'hour',
  'minutes':'minute'
}
export function GetAge(date) {
  if(date == null)
    return  i18next.t('time_units.just');
  if(typeof(date) === 'string')
    date = ParseDateTime(date);

  const dif = date.diffNow(Object.keys(spans));
  let _dif;
  for(let i in spans){
    _dif = dif.values[i] * -1;
    if(_dif >= 1)
      return Math.round(_dif) + _ +  i18next.t('time_units.'+spans[i], {count: _dif});
  }
  return  i18next.t('time_units.just');
}

function getTwoDigits(value) {
  if (value < 10) return "0" + value;

  return value;
}

function getParts() {
  let parts = document.location.pathname.split(CONST._slash);
  if (parts[0] === CONST._empty) parts.splice(0, 1);

  if (parts[parts.length - 1] === CONST._empty)
    parts.splice(parts.length - 1, 1);

  return parts;
}
/*eslint-disable */
const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const IS_URL_FILE_REGEX = /files/;
/*eslint-enable */
export function IsUrl(txt) {
  if (URL_REGEX.test(txt) || txt.indexOf("localhost:") > -1) return true;

  return false;
}

export function IsFileUrl(txt) {
  return IS_URL_FILE_REGEX.test(txt);
}

//C:\Daten\Kunden\Klardenker\Meet\App\api\Klardenker.Meet.api\storage\app\files

//C:\Daten\Kunden\Klardenker\Meet\App\api\Klardenker.Meet.api\public

export function canEditEntity(role) {
  //works for company and workspace
  if (role <= 20) {
    return true;
  }
  return false;
}

export function canEditWorkspace(role) {
  if (role <= 20) {
    return true;
  }
  return false;
}

export const arrayEqualsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};
