import {
  fetchLogin,
  fetchLogout,
  fetchPassResetRequest,
  fetchPassReset,
  fetchRegister,
  fetchUserUpdate,
  _invite as inviteUser,
  fetchConfirmEmail,
  fetchRequestConfirmEmail,
  fetchMyUser,
  _updateUserInstructions,
} from "./../actions/login";

import { _doRequest, Endpoints } from "./_api.js";

export function auth(data) {
  return _doRequest(Endpoints.auth, fetchLogin, data);
}

export function logout() {
  return _doRequest(Endpoints.logout, fetchLogout);
}

export function login(data) {
  return _doRequest(Endpoints.login, fetchLogin, data);
}
export function magicLogin(data) {
  return _doRequest(Endpoints.magic_login, fetchLogin, data);
}

export function passResetRequest(data) {
  return _doRequest(Endpoints.requestreset, fetchPassResetRequest, data);
}

export function confirmEmail(data) {
  return _doRequest(Endpoints.confirm_email, fetchConfirmEmail, data);
}
export function requestConfirmEmail(data) {
  return _doRequest(
    Endpoints.request_confirm_email,
    fetchRequestConfirmEmail,
    data
  );
}

export function requestConfirmTerms(data) {
  return _doRequest(
    Endpoints.request_confirm_terms,
    fetchRequestConfirmEmail,
    data
  );
}

export function register(data) {
  return _doRequest(Endpoints.register, fetchRegister, data);
}

export function _invite(data) {
  return _doRequest(Endpoints.invite, inviteUser, data);
}

export function _invite_workspace(data) {
  return _doRequest(Endpoints.invite_workspace, inviteUser, data);
}

export function setNewPass(data) {
  return _doRequest(Endpoints.reset, fetchPassReset, data);
}

export function updateUser(data) {
  return _doRequest(Endpoints.user_update, fetchUserUpdate, data);
}

export function getMyself(data) {
  return _doRequest(Endpoints.me, fetchMyUser, data);
}

export function updateUserInstructions(data) {
  return _doRequest(
    Endpoints.update_user_instructions,
    _updateUserInstructions,
    data
  );
}
