import { GenericAction } from "./../actions/api.js";

export const APP_MESSAGE_TYPE_INFO = "APP_MESSAGE_TYPE_INFO";
export const APP_MESSAGE_TYPE_WARNING = "APP_MESSAGE_TYPE_WARNING";
export const APP_MESSAGE_TYPE_ERROR = "APP_MESSAGE_TYPE_ERROR";
export const APP_MESSAGE_TYPE_CLEAR = "APP_MESSAGE_TYPE_CLEAR";
export const APP_MESSAGE_TYPE_TODO = "APP_MESSAGE_TYPE_TODO";

export const APP_MESSAGE_ADD = "APP_MESSAGE_ADD";
export const APP_MESSAGE_REMOVE = "APP_MESSAGE_REMOVE";

export const ALERT_MESSAGE_TYPE_SHOW = "ALERT_MESSAGE_TYPE_SHOW";
export const ALERT_MESSAGE_TYPE_HIDE = "ALERT_MESSAGE_TYPE_HIDE";

export const SHOW_OVERLAY_TYPE = "SHOW_OVERLAY_TYPE";
export const SET_USER_TYPE = "SET_USER_TYPE";

export const LOAD_LOCAL_DATA = "LOAD_LOCAL_DATA";

export const APP_LOGOUT_USER = "APP_LOGOUT_USER";

export const APP_BASKET_DELETE = "APP_BASKET_DELETE";
export const APP_BASKET_ADD = "APP_BASKET_ADD";
export const APP_SHOW_STAGE = "APP_SHOW_STAGE";
export const APP_SET_MEETING = "APP_SET_MEETING";

export const PUBLICDATA_PENDING = "PUBLICDATA_PENDING";
export const PUBLICDATA_SUCCESS = "PUBLICDATA_SUCCESS";
export const PUBLICDATA_ERROR = "PUBLICDATA_ERROR";

export const APP_MEDIA_SHOW = "APP_MEDIA_SHOW";
export const APP_MEDIA_HIDE = "APP_MEDIA_HIDE";
export const APP_INSTRUCTIONS_SHOW = "APP_INSTRUCTIONS_SHOW";
export const APP_INSTRUCTIONS_HIDE = "APP_INSTRUCTIONS_HIDE";

export const APP_ADMINMODE_SET = "APP_ADMINMODE_SET";

export const APP_ANALYTICS_LOAD = "APP_ANALYTICS_LOAD";
export const APP_SOCIAL_USER_BOARD_OPENED = "APP_SOCIAL_USER_BOARD_OPENED";


export const APP_ENTITIYPICKER = {
  SHOW: "APP_ENTITIYPICKER_SHOW",
  HIDE: "APP_ENTITIYPICKER_HIDE",
};

export function getPublicData(type, info, payload) {
  return GenericAction(
    type,
    info,
    payload,
    PUBLICDATA_PENDING,
    PUBLICDATA_SUCCESS,
    PUBLICDATA_ERROR
  );
}
export function setAdminMode(val) {
  return { type: APP_ADMINMODE_SET, val: val };
}

export function hideEntityPicker(val) {
  return { type: APP_ENTITIYPICKER.HIDE, val: val };
}
export function showEntityPicker(val) {
  return { type: APP_ENTITIYPICKER.SHOW, val: val };
}

export function logOutUser() {
  return { type: APP_LOGOUT_USER };
}

export function _depr_setUserAction(data) {
  return {
    type: SET_USER_TYPE,
    data: data,
  };
}

export function loadLocalDataFunc(data) {
  return {
    type: LOAD_LOCAL_DATA,
    data: data,
  };
}

export function showOverlayAction(data) {
  return {
    type: SHOW_OVERLAY_TYPE,
    data: data,
  };
}

export function appMessageAdd(title, text, type) {
  return {
    type: APP_MESSAGE_ADD,
    message: { title: title, text: text, type: type },
  };
}

export function appMessageRemove(key) {
  return {
    type: APP_MESSAGE_REMOVE,
    key: key,
  };
}

export function appMediaShow(path, options = null, settings = null) {
  return { type: APP_MEDIA_SHOW, path: path, options: options, settings:settings };
}

export function appMediaHide() {
  return { type: APP_MEDIA_HIDE };
}

export function appInstructionsShow(val, options = null) {
  return { type: APP_INSTRUCTIONS_SHOW, instructions: val, options: options };
}

export function appInstructionsHide() {
  return { type: APP_INSTRUCTIONS_HIDE };
}

export function alertMessageShow(msg) {
  return {
    type: ALERT_MESSAGE_TYPE_SHOW,
    message: msg,
  };
}
export function alertMessageHide(type) {
  return {
    type: ALERT_MESSAGE_TYPE_HIDE,
    company_id: null,
  };
}

export function basket_delete(val) {
  return { type: APP_BASKET_DELETE, val: val };
}
export function basket_add(val) {
  return { type: APP_BASKET_ADD, val: val };
}

export function show_stage(val) {
  return { type: APP_SHOW_STAGE, val: val };
}
export function set_meeting(val) {
  return { type: APP_SET_MEETING, val: val };
}

export function _loadAnalytics(type, info, payload) {
  return { type: APP_ANALYTICS_LOAD, status: type, payload:payload, info:info };
}

export function _pushAnalytics(type, info, payload) {
  return { type: APP_ANALYTICS_LOAD, status: type, payload:payload, info:info };
}
export function _setSocialUserboardOpened(bool) {
  return { type: APP_SOCIAL_USER_BOARD_OPENED, val: bool };
}
