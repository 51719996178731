import { CONST } from "../../Constants";

export function ParseDocument(raw) {
  try {
    let doc = new Document();
    doc.uuid = raw.uuid;
    doc.name = raw.name;
    doc.description = raw.description;
    doc.is_public = raw.is_public === true || raw.is_public === 1;
    doc.type = raw.type;
    doc.path = raw.path;
    doc.used = raw.used;
    doc.workspace_id = raw.workspace_id;
    doc.accessibility = raw.accessibility;
    doc.show_daily_notification = raw.show_daily_notification
      ? raw.show_daily_notification
      : 0;
    return doc;
  } catch (e) {
    console.error("Error parsing document", raw);
  }
  return null;
}

export class Document {
  constructor() {
    this.name = CONST._empty;
    this.description = CONST._empty;
    this.is_public = false;
    this.path = CONST._empty;
    this.type = CONST._empty;
    this.used = 0;
    this.workspace_id = CONST._empty;
    this.uuid = CONST._empty;
    this.accessibility = CONST._empty;
    this.show_daily_notification = 0;
  }

  IsExternal() {
    if (
      this.path.toLowerCase().indexOf("http:") > -1 ||
      this.path.toLowerCase().indexOf("https:") > -1 ||
      this.path.toLowerCase().indexOf("//") > -1
    )
      return true;
    return false;
  }
}

Document.Types = {
  html: "html",
  pdf: "pdf",
  image: "image",
  video: "video",
  text: "text",
  object_view: "object_view",
};
