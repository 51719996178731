import { CONST } from "Constants";

export function ParseStages(raw) {
  let stages = [];
  if (Array.isArray(raw)) {
    for (let i in raw) {
      let stage = ParseStage(raw[i]);
      if (stage) stages.push(stage);
    }
  } else {
    let stage = ParseStage(raw);
    if (stage) stages.push(stage);
  }

  return stages;
}
export function ParseStage(raw) {
  try {
    let stage = new Stage();
    stage.name = raw.name;
    stage.path = raw.path;
    if (raw.custom_json && Object.keys(raw.custom_json))
      stage.custom_json =
        typeof raw.custom_json === "string"
          ? JSON.parse(raw.custom_json)
          : raw.custom_json;

    stage.active = raw.active === 1 || raw.active === true;
    stage.type = raw.type;
    stage.link_type = raw.link_type;
    stage.workspace_id = raw.workspace_id;
    stage.sort_id = raw.sort_id;
    stage.uuid = raw.uuid;
    stage.preview_image = raw.preview_image;
    stage.description = raw.description;
    stage.content = raw.content;

    return stage;
  } catch (e) {
    console.error("Error parsing stage", e, raw);
  }
  return null;
}

export class Stage {
  constructor() {
    this.uuid = CONST._empty;
    this.name = CONST._empty;
    this.path = CONST._empty;
    this.custom_json = {};
    this.type = CONST._empty;
    this.workspace_id = CONST._empty;
    this.link_type = CONST._empty;
    this.preview_image = CONST._empty;
    this.description = CONST._empty;
    this.active = false;
    this.sort_id = 0;
  }
}

Stage.Types = {
  _3d: "_3d",
  _360: "_360",
  html: "html",
  iframe: "iframe",
};

Stage.LinkTypes = {
  owner: "owner",
  share: "share",
};
